export default {
  GET_TERMS_OF_USE_MODAL_STATUS: (state) => state.showTermsOfUseModal,
  GET_EXCHANGE_SKIN: (state) => state.exchangeSkin,
  GET_ACTIVE_NAV: (state) => state.activeNav,
  GET_DEPOSIT_STATUS: (state) => state.depositStatus,
  GET_WITHDRAW_STATUS: (state) => state.withdrawStatus,
  GET_TRANSFER_STATUS: (state) => state.transferStatus,
  MEMBER: (state) => state.member,
  IS_LOGIN: (state) => state.member !== null,
  GET_LANG: (state) => state.lang,
  LOGIN_TIMES: (state) => state.loginTimes,
  GET_STATUS_CODE: (state) => state.responseStatusCode,
  GET_TRADING_STATUS: (state) => state.tradingStatus,
};
