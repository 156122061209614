import Vue from "vue";
import Toast from "vue-toastification";
import i18n from "/src/plugins/i18n";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";


const options = {
  position: "bottom-center",
  closeButton: false,
  hideProgressBar: true,
  timeout: 5000,
  closeOnClick: false,
  toastClassName: "custom-toast",
  bodyClassName: "custom-toast-body",
  rtl:  localStorage.getItem("lang") === "fa",
  zIndex: 1000,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(t => t.type === toast.type).length > 3) return false;
    return toast;
  }
};

Vue.use(Toast, options);
