export default {
    assets: "دارایی‌ها",
    totalAssets: "ارزش کل دارایی",
    crypto: "رمزارز",
    balance: "موجودی کل",
    irrBalance: "معادل ریالی موجودی",
    usdBalance: "معادل دلاری موجودی",
    showUsdBalance: "نمایش دلاری موجودی",
    showIrrBalance: "نمایش ریالی موجودی",
    showAvailableAsset: "عدم نمایش موجودی صفر",
    frozen: "موجودی بلاک شده",
    operation: "عملیات",
    disabledDeposit: "شما قادر به ثبت درخواست واریز نیستید، لطفا با پشتیبانی تماس بگیرید!",
    deposit: "واریز",
    withdraw: "برداشت",
    transfer: "انتقال",
    disabledWithdraw: "شما قادر به ثبت درخواست برداشت نیستید، لطفا با پشتیبانی تماس بگیرید!",
    disabledTransfer: "شما قادر به ثبت درخواست انتقال نیستید، لطفا با پشتیبانی تماس بگیرید!",
    trade: "معامله",
    checkWallet: "بروزرسانی موجودی",
    modalText01:"بازار دمو یک بازار آموزشی و تستی است که به شما اجازه می‌دهد با ارزهای مجازی مانند V-USDT، V-BTC و V-ETH معامله کنید. این ارزها فقط برای آزمایش و یادگیری هستند و هیچ ارزش واقعی ندارند. شما نمی‌توانید آن‌ها را به ریال یا دلار تبدیل کنید یا از سامانه خارج کنید. شما فقط می‌توانید آن‌ها را در بازار دمو با یکدیگر مبادله کنید.",
    modalText02:"همه کاربران بعد از ثبت نام به صورت رایگان مقداری از ارز V-USDT دریافت می‌کنید. شما می‌توانید با استفاده از این ارز، سفارش خرید یا فروش برای ارزهای دیگر در بازار دمو ثبت کنید. برای مثال، شما می‌توانید V-USDT خود را به V-BTC تبدیل کنید. برای این کار، شما باید قیمت و تعداد مورد نظر خود را وارد کنید و سپس سفارش خود را تأیید کنید. سامانه به صورت خودکار سفارش شما را با سفارشات مناسب دیگر کاربران همخوانی می‌دهد و معامله را انجام می‌دهد.",
    modalText03:"شما با استفاده از این بازار میتوانید بدون در خطر انداختن دارایی واقعی خود با قابلیت های سیستم آشنا شده و سپس به معاملات واقعی بپردازید.",
    demoTrading:"معاملات آزمایشی - Demo Trading",
    trialCurrency: "'ارز آزمایشی'"
}