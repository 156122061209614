export default {
  assets: "الأصول",
  totalAssets: "القيمة الإجمالية للأصول",
  crypto: "العملات المشفرة",
  balance: "إجمالي الرصيد",
  irrBalance: "ما يعادل الرصيد بالريال",
  usdBalance: "ما يعادل الرصيد بالدولار",
  showUsdBalance: "عرض الرصيد بالدولار",
  showIrrBalance: "عرض الرصيد بالريال",
  showAvailableAsset: "إخفاء الرصيد الصفري",
  frozen: "الرصيد المجمد",
  operation: "عملية",
  disabledDeposit: "لا يمكنك طلب إيداع، يرجى الاتصال بالدعم!",
  deposit: "إيداع",
  withdraw: "سحب",
  transfer: "تحويل",
  disabledWithdraw: "لا يمكنك طلب سحب، يرجى الاتصال بالدعم!",
  disabledTransfer: "لا يمكنك طلب تحويل، يرجى الاتصال بالدعم!",
  trade: "تداول",
  checkWallet: "تحديث الرصيد",
  modalText01: "سوق العرض التجريبي هو سوق تعليمي واختباري يسمح لك بالتداول بالعملات الافتراضية مثل V-USDT، V-BTC، و V-ETH. هذه العملات مخصصة فقط للتجربة والتعلم ولا تحمل أي قيمة حقيقية. لا يمكنك تحويلها إلى ريال أو دولار أو سحبها من النظام. يمكنك فقط تبادلها في سوق العرض التجريبي مع بعضها البعض.",
  modalText02: "يحصل جميع المستخدمين على بعض من V-USDT مجانًا بعد التسجيل. يمكنك استخدام هذه العملة لوضع أوامر بيع أو شراء لعملات أخرى في سوق العرض التجريبي. على سبيل المثال، يمكنك تحويل V-USDT إلى V-BTC. للقيام بذلك، تحتاج إلى إدخال السعر والكمية المطلوبة ثم تأكيد طلبك. سيقوم النظام تلقائيًا بمطابقة طلبك مع الطلبات المناسبة من المستخدمين الآخرين وتنفيذ الصفقة.",
  modalText03: "يمكنك استخدام هذا السوق لتتعرف على إمكانيات النظام بدون المخاطرة بأصولك الحقيقية ثم الانتقال إلى التداول الحقيقي.",
  demoTrading: "تداول تجريبي",
  trialCurrency: "العملة التجريبية"
};
