export default {
    userAccount: "User Account",
    userName: "Username:",
    email: "Email:",
    phoneNumber: "Phone Number:",
    termsOfUse: "Terms and Conditions",
    termsOfUseTip: "Terms and Conditions for Using Excoino Services",
    view: "View",
    loginPss: "Login Password",
    loginTip: "Same as the password on the Excoino website.",
    change: "Change",
    withdrawPss: "Secure Withdrawal Password",
    withdrawTip: "Entering the secure withdrawal password is necessary for withdrawals from Excoino Market",
    transferPss: "Secure Transfer Password",
    transferTip: "Entering the secure transfer password is necessary for transfers from Excoino Market",
    set: "Set",
    confirmWithdrawPss: "Confirm Secure Withdrawal Password",
    confirmTransferPss: "Confirm Secure Transfer Password",
    save: "Save",
    reset: "Reset",
    currentWithdrawPss: "Current Secure Withdrawal Password",
    newWithdrawPss: "New Secure Withdrawal Password",
    confirmNewWithdrawPss: "Repeat New Secure Withdrawal Password",
    currentTransferPss: "Current Secure Transfer Password",
    newTransferPss: "New Secure Transfer Password",
    confirmNewTransferPss: "Repeat New Secure Transfer Password",
    forgotPss: "Forgot your password?",
    confirmationCode: "Confirmation Code",
    smsCode: "SMS Confirmation Code",
    loginCharacterLimits: "Please enter at least 6 characters for the new login password",
    conflictLoginPss: "New login password is conflicting",
    withdrawCharacterLimits: "Please enter at least 6 characters for the secure withdrawal password",
    enterWithdrawRepeat: "Please enter the repeat of the new secure withdrawal password",
    conflictWithdrawPss: "Repeat secure withdrawal password does not match",
    enterTransferPss: "Please enter the current secure transfer password",
    transferCharacterLimits: "Please enter at least 6 characters for the secure transfer password",
    enterTransferRepeat: "Please enter the repeat of the new secure transfer password",
    conflictTransferPss: "Repeat secure transfer password does not match",
    getCode: "Get Code",
    enterEmail: "Please enter your email",
    enterCode: "Please enter the confirmation code",
    enterPhoneNumber: "Please enter your phone number",
    enterLoginPss: "Please enter the main login password",
    enterRealName: "Please enter your real name",
    enterId: "Please enter your ID number",
    bindPhoneTip: "Please bind your phone number first",
    resetWithdrawPss: "Reset Fund Password",
    resetTransferPss: "Reset Fund Password",
    successSave: "Successfully saved",
    fillFields: "All relevant fields must be filled!",
    confirm: "Confirm",
    registerToGetCode: "To receive the confirmation code, register your mobile number in your Excoino user account.",
    totalAssetValue:"Total Asset Value"
  };
  