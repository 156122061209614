import { render, staticRenderFns } from "./TermsOfUseModal.html?vue&type=template&id=f348c2c4&scoped=true&"
import script from "./TermsOfUseModal.js?vue&type=script&lang=js&"
export * from "./TermsOfUseModal.js?vue&type=script&lang=js&"
import style0 from "./terms-of-use-modal.scss?vue&type=style&index=0&id=f348c2c4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f348c2c4",
  null
  
)

export default component.exports