export default {
  introduceToFriends: "قدِّم لأصدقائك",
  receivedGiftsValue: "قيمة الهدايا المستلمة",
  totalInvited: "إجمالي المدعوين",
  person: "شخص",
  affiliateCode: "كود الإحالة",
  affiliateLink: "رابط الإحالة",
  copySuccess: "تم نسخ العنوان بنجاح",
  inviteFriends: "ادعُ أصدقاءك إلى سوق Excoino واربح المكافآت.",
  username: "اسم المدعو (اسم المستخدم)",
  registerTime: "تاريخ التسجيل",
  giftAmount: "قيمة الهدايا المستلمة",
  more: "تفاصيل",
  action: "إجراء",
  rewardsDetails: "تفاصيل الهدايا",
  rewardAmount: "قيمة الهدية",
  rewardCount: "عدد الهدايا",
  successfulCopyRefCode: "تم نسخ كود الإحالة بنجاح",
  successfulCopyRefLink: "تم نسخ رابط الإحالة بنجاح",
  qrCodeAddress: "كود QR لكود الإحالة"
};
