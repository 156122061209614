import $axios from "../../../utiles/axios";
import {
  appendToFormData,
  appendParam,
  appendQueryString,
} from "../../../utiles/apiDataFormat";

export default {
  ADD_ORDER_SERVICE: ({}, payload) => {
    return $axios.post("/exchange/order/add", appendToFormData(payload));
  },
  GET_OPEN_ORDERS_SERVICE: ({}, payload) => {
    return $axios.post("/exchange/order/current", appendToFormData(payload));
  },
  HISTORY_SERVICE: ({}, payload) => {
    return $axios.post("/exchange/order/history", appendToFormData(payload));
  },
  GET_ORDER_DETAILS_SERVICE: ({}, payload) => {
    return $axios.post("/exchange/order/detail/v2" + appendParam(payload));
  },
  GET_FAVORED_MARKETS_SERVICE: ({},payload) => {
    // return $axios.post("/exchange/favor/find");
    return $axios.post("/exchange/favor/find-favor-symbol-detail", appendToFormData(payload));
  },
  GET_ALL_FAVORED_MARKETS_SERVICE: ({},payload) => {
    return $axios.post("/exchange/favor/find");
  },
  GET_DASHBOARD_FAVORITE_MARKETS_SERVICE: () => {
    return $axios.get("/exchange/favor/find-by-full-finance-info");
  },
  ADD_FAVORED_MARKET_SERVICE: ({}, payload) => {
    return $axios.post("/exchange/favor/add", appendToFormData(payload));
  },
  DELETE_FAVORED_MARKET_SERVICE: ({}, payload) => {
    return $axios.post("/exchange/favor/delete", appendToFormData(payload));
  },
  CANCEL_ORDER_SERVICE: ({}, payload) => {
    return $axios.post("/exchange/order/cancel" + appendParam(payload));
  },
  GET_ORDER_STATUS_LIST_SERVICE: () => {
    return $axios.get("/exchange/order/status/types");
  },
  GET_PRECONDITON_ORDER_STATUS_LIST_SERVICE: () => {
    return $axios.get("/exchange/order-precondition/status/types");
  },
  GET_ORDERS_HISTORY_LIST_SERVICE: ({}, payload) => {
    return $axios.post(
      "/exchange/order/personal/history",
      appendToFormData(payload)
    );
  },
  GET_PRECONDITION_ORDERS_HISTORY_LIST_SERVICE: ({}, payload) => {
    return $axios.post(
      "/exchange/order-precondition/personal/all-determined-preconditions",
      appendToFormData(payload)
    );
  },
  GET_ORDERS_CURRENT_LIST_SERVICE: ({}, payload) => {
    return $axios.post(
      "/exchange/order/personal/current",
      appendToFormData(payload)
    );
  },
  GET_TOP_CURRENT_ORDERS_SERVICE: () => {
    return $axios.get("/exchange/order/personal/top-current");
  },
  GET_TOP_PRECONDITION_ORDERS_SERVICE: () => {
    return $axios.get("/exchange/order-precondition/personal/top5-active-preconditions");
  },
  GET_EXCHANGE_ORDER_DETAIL_SERVICE: ({}, payload) => {
    return $axios.post("/exchange/order/detail/v2" + appendParam(payload));
  },
  GET_PRECONDITION_ORDER_DETAIL_BY_ORDER_ID_SERVICE: ({}, payload) => {
    return $axios.get(
      "/exchange/order-precondition/precondition-details-by-order-id" +
        appendQueryString(payload)
    );
  },
  GET_PRECONDITION_ORDER_DETAIL_BY_ID_SERVICE: ({}, payload) => {
    return $axios.get(
      "/exchange/order-precondition/details-by-precondition-id" +
        appendQueryString(payload)
    );
  },
  ADD_PRECONDITION_ORDER_SERVICE: ({}, payload) => {
    return $axios.post(
      "/exchange/order-precondition/add",
      appendToFormData(payload)
    );
  },
  GET_ALL_ACTIVE_PRECONDITIONS_SERVICE: ({}, payload) => {
    return $axios.post(
      "/exchange/order-precondition/all-active-preconditions",
      appendToFormData(payload)
    );
  },
  GET_ALL_ACTIVE_PRECONDITIONS_BY_FILTER_SERVICE: ({}, payload) => {
    return $axios.post(
      "exchange/order-precondition/personal/all-active-preconditions",
      appendToFormData(payload)
    );
  },
  GET_ALL_ORDER_TYPES_HISTORY_SERVICE: ({}, payload) => {
    return $axios.post(
      "/exchange/order/all-order-types-history",
      appendToFormData(payload)
    );
  },
  GET_ALL_ORDER_TYPES_HISTORY_BY_FILTER_SERVICE: ({}, payload) => {
    return $axios.post(
      "/exchange/order/personal/all-order-types-history",
      appendToFormData(payload)
    );
  },
  GET_ALL_PRECONDITIONS_HISTORY_SERVICE: ({}, payload) => {
    return $axios.post(
      "/exchange/order-precondition/all-determined-preconditions",
      appendToFormData(payload)
    );
  },
  CANCEL_PRECONDITION_ORDER_SERVICE: ({}, payload) => {
    return $axios.post(
      "/exchange/order-precondition/cancel" + appendParam(payload)
    );
  },
  ADD_OCO_ORDER_SERVICE: ({}, payload) => {
    return $axios.post("/exchange/oco/add", appendToFormData(payload));
  },
  GET_ALL_TRADE_BASE_COIN: () => {
    return $axios.get("/exchange/exchange-coin/units");
  },
};
