import $axios from "../../../utiles/axios";
import {
  appendToFormData,
  appendQueryString,
} from "../../../utiles/apiDataFormat";

export default {
  SET_PAIR_COIN_SCALES: ({ commit }, payload) => {
    commit("SET_PAIR_COIN_SCALES", payload);
  },
  INIT_WEBSOCKET_SERVICE: () => {
    return $axios.post("/market/market-ws");
  },
  GET_SYMBOL_THUMB_SERVICE: () => {
    return $axios.post("/market/symbol-thumb");
  },
  GET_SYMBOL_THUMB_TREND_SERVICE: ({ commit }, payload) => {
    const {pageSize,pageNo,baseSymbol,coinSymbol}=payload
    return $axios.post(`/market/symbol-thumb-by-base-trend/?pageSize=${pageSize}&pageNo=${pageNo}`, {baseSymbol,coinSymbol});
  },
  GET_TRENDS_REPORT: () => {
    return $axios.get("/market/hot-reports");
  },
  GET_EXCHANGE_PLATE_SERVICE: () => {
    return $axios.post("/market/exchange-plate");
  },
  GET_EXCHANGE_PLATE_MINI_SERVICE: ({}, payload) => {
    return $axios.post(
      "/market/exchange-plate-mini",
      appendToFormData(payload)
    );
  },
  GET_EXCHANGE_PLATE_FULL_SERVICE: ({}, payload) => {
    return $axios.post(
      "/market/exchange-plate-full",
      appendToFormData(payload)
    );
  },
  GET_LATEST_TRADE_SERVICE: ({}, payload) => {
    return $axios.post("/market/latest-trade", appendToFormData(payload));
  },
  GET_SYMBOL_INFO_SERVICE: ({}, payload) => {
    return $axios.post("/market/symbol-info", appendToFormData(payload));
  },
  GET_COIN_INFO_SERVICE: ({}, payload) => {
    return $axios.post("/market/coin-info", appendToFormData(payload));
  },
  GET_INDEX_INFO_SERVICE: () => {
    return $axios.post("/market/index_info");
  },
  GET_SYMBOL_THUMB_BY_BASE_TREND_SERVICE: ({}, payload) => {
    return $axios.get(
      "/market/symbol-thumb-by-base-trend" + appendQueryString(payload)
    );
  },
  GET_BASE_SYMBOLS_SERVICE: () => {
    return $axios.get("/market/base-symbols-with-type/v2");
  },
  GET_FRONT_COIN_SCALES_SERVICE: () => {
    return $axios.get("market/coins-front-scales");
  },
  GET_PAIR_COIN_SCALES_SERVICE: () => {
    return $axios.get("market/symbols-front-scales");
  },
};
