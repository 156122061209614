export default {
  confirmTermsOfUse: "أوافق",
  denyTermsOfUse: "لا أوافق",
  title: "الشروط والأحكام",
  subTitle: "شركة تطوير البيانات أريان",
  terms: [
    {
      id: "1",
      title: "1. المقدمة",
      desc: [
        {
          id: "1",
          text: "شركة تطوير البيانات أريان (شركة مساهمة خاصة) برقم الهوية الوطنية 14008195510 توضح شروط وأحكام استخدام خدمات سوق Excoino في هذا المستند. هذا المنتج مصمم لتقديم خدمات العملات المشفرة للمستخدمين وسيوفر الخدمات للمستخدمين وفقًا للشروط والأحكام التالية. تعتبر الشروط الحالية عقدًا نهائيًا وقابلًا للتنفيذ، وفقًا للمادتين 10 و219 من القانون المدني، التي تحكم العلاقات بين المستخدمين والشركة. دخول المستخدمين إلى سوق Excoino يشكل قبولًا لجميع الشروط والأحكام المذكورة في هذا المستند. لذلك، من الضروري قراءة هذه الشروط والأحكام بعناية قبل استخدام خدمات الشركة."
        }
      ]
    },
    {
      id: "2",
      title: "2. التعريفات والشروط",
      desc: [
        {
          id: "1",
          text: "1- الشركة: تشير إلى شركة تطوير البيانات أريان (شركة مساهمة خاصة) برقم الهوية الوطنية 14008195510، مالكة منتج سوق Excoino."
        },
        {
          id: "2",
          text: "2- سوق Excoino: مساحة مخصصة في <a href='https://market.excoino.com' target='_blank'>market.excoino.com</a> لشراء وبيع العملات المشفرة من خلال لوحة التداول على موقع Excoino الإلكتروني، ومتاحة للمستخدمين."
        },
        {
          id: "3",
          text: "3- محفظة العملات المشفرة: مكان لتخزين العملات وإيداعها وسحبها على شبكة البلوكشين."
        },
        {
          id: "4",
          text: "4- المستخدم: فرد يدخل ويزور ويستخدم سوق Excoino بكامل إرادته، ويلتزم تمامًا بالقوانين واللوائح الواردة في هذا المستند لتلقي الخدمات ذات الصلة من الشركة."
        },
        {
          id: "5",
          text: "5- حساب المستخدم: حساب يتم إنشاؤه بواسطة الأفراد تحت مسؤوليتهم الكاملة فيما يتعلق بدقة واكتمال وشفافية المعلومات المقدمة، وفقًا لقوانين جمهورية إيران الإسلامية والشروط التي تحددها الشركة في Excoino."
        },
        {
          id: "6",
          text: "6- العملة المشفرة: فئة من المال الرقمي والعملات التي تستخدم التشفير للمعاملات المالية."
        },
        {
          id: "7",
          text: "7- العملة المشفرة المقبولة: العملة المشفرة المدرجة والمعلنة من قبل سوق Excoino والمقبولة من قبل الشركة للشراء والبيع."
        },
        {
          id: "8",
          text: "8- القوة القاهرة: تشير إلى أي حدث خارجي لا يمكن تجنبه ولا يمكن السيطرة عليه وغير متوقع، ولا يُعزى إلى فعل أو إهمال أي من الطرفين (الشركة والمستخدم). تشمل الأمثلة الفيضانات والزلازل والحرائق والحروب والإضرابات والأوبئة وانقطاع الإنترنت الواسع النطاق وانقطاع الكهرباء وغيرها من الأحداث ذات الصلة."
        }
      ]
    },
    {
      id: "3",
      title: "3. عام",
      desc: [
        {
          id: "1",
          text: "1- بدخول لوحة سوق Excoino، يوافق المستخدم على أنه قد قرأ وفهم وقبل الشروط والأحكام الواردة في هذا المستند بالكامل وبدقة. لذلك، أي اعتراضات أو ادعاءات مستقبلية بالجهل أو أي قضايا أخرى يثيرها المستخدم في هذا الصدد غير صالحة."
        },
        {
          id: "2",
          text: "ملاحظة: أي علامة أو رقم أو كلمة أو صورة أو علامة إلكترونية تثبت هوية الموقع وتكون مرفقة بهذا المستند أو المستندات ذات الصلة تعتبر توقيعًا إلكترونيًا وتتمتع بنفس صلاحية ومصداقية التوقيع بخط اليد. يلزم الموقع بالشروط الواردة في المستند، وأي إثبات بخلاف ذلك غير مقبول."
        },
        {
          id: "3",
          text: "2- الشركة مخولة بإجراء تغييرات على شروط هذا المستند في أي وقت، كليًا أو جزئيًا. سيتم إبلاغ المستخدم بهذه التغييرات بوسائل مناسبة. إذا استمر المستخدم في استخدام خدمات سوق Excoino بعد الإبلاغ، فإن ذلك يشكل قبولًا لجميع التغييرات من قبل المستخدمين."
        },
        {
          id: "4",
          text: "3- تقدم الشركة الخدمات التالية للمستخدمين في سوق Excoino:",
          detail: [
            {
              id: "5",
              text: "شراء وبيع العملات المشفرة المقبولة بين المستخدمين،"
            },
            {
              id: "6",
              text: "إيداع وسحب العملات المشفرة المقبولة،"
            },
            {
              id: "7",
              text: "تحويل العملات المشفرة المقبولة إلى ومن Excoino."
            }
          ]
        },
        {
          id: "8",
          text: "4- الرسوم المفروضة في الفاتورة لا تتعلق برسوم الشبكة ويتم حسابها وتحصيلها بشكل منفصل."
        },
        {
          id: "9",
          text: "5- تحدد المستخدمين أسعار شراء وبيع العملات المشفرة في سوق Excoino. يتم عرض معدل الرسوم لخدمات سوق Excoino لكل أمر ويتم تحصيلها من كلا الطرفين عند إتمام الصفقة."
        },
        {
          id: "10",
          text: "6- أي رسوم أو عمولات أو تكاليف أخرى تفرضها نظام البلوكشين أو العملات المشفرة على المستخدم هي مسؤولية المستخدم وحده. يجب على المستخدم مراجعة هذه الأمور قبل الطلب، وليس لهذه التكاليف علاقة بالشركة، والشركة ليست مسؤولة عن هذه التكاليف."
        },
        {
          id: "11",
          text: "7- اختيار العملات المشفرة لإدراجها وقبولها في سوق Excoino يعود إلى تقدير الشركة. الشركة مخولة بزيادة أو تقليل قائمة العملات المشفرة المقبولة في أي وقت."
        },
        {
          id: "12",
          text: "8- سوق Excoino ليس له علاقة بمواقع القمار والمراهنات. إذا استخدم المستخدمون خدمات سوق Excoino لهذه المواقع، سيتم تعليق حساباتهم على الفور، وستتخذ الشركة إجراءات قانونية."
        },
        {
          id: "13",
          text: "9- إذا تسببت الأنشطة غير القانونية أو الخبيثة من قبل المستخدمين في ضرر للشركة أو منتجاتها أو مستخدميها، يمكن للشركة متابعة الإجراءات الجنائية والمدنية من خلال القنوات القانونية لفرض العقوبات واسترداد جميع الأضرار الممكنة."
        },
        {
          id: "14",
          text: "10- جميع الملكية الفكرية المتعلقة بسوق Excoino مملوكة حصريًا للشركة، وحق الاستغلال الحصري يعود للشركة. أي انتهاك لهذه الملكية سيؤدي إلى مقاضاة قانونية."
        },
        {
          id: "15",
          text: "11- جميع الإخطارات والإعلانات من سوق Excoino سيتم إرسالها إلى البريد الإلكتروني أو رقم الهاتف الذي قدمه المستخدمون أثناء التسجيل في Excoino أو من خلال حساب المستخدم. يمكن للمستخدمين أيضًا الاتصال بالعنوان المدرج في قسم اتصل بنا للحصول على معلومات أساسية أو لتقديم اقتراحات وأسئلة."
        },
        {
          id: "16",
          text: "12- إذا تعطل خدمات الشركة بسبب القوة القاهرة، فإن الشركة ليست مسؤولة."
        },
        {
          id: "17",
          text: "13- إذا تم تحديد سلوك غير قياسي أو غير حقيقي أو غير قانوني من قبل المستخدم فيما يتعلق بالأنشطة في سوق Excoino، ستنذر الشركة المستخدم وتعليق حسابه. يتطلب إعادة تفعيل الحساب إعادة التحقق من الهوية والنشاط الصحيح للمستخدم وفقًا لما تحدده الشركة. إذا كان السلوك غير قانوني أو يتعارض مع القوانين واللوائح، سيتم اتخاذ الإجراءات المناسبة وفقًا للقوانين المعنية، وسيتم الإبلاغ عن الأمر إلى السلطات المختصة."
        },
        {
          id: "18",
          text: "14- أي استخدام لاسم وشعار سوق Excoino بدون إذن كتابي مسبق من الشركة يعرض المستخدم للملاحقة القانونية."
        },
        {
          id: "19",
          text: "15- يمكن إيداع وسحب العملات وتحويلها من Excoino إلى سوق Excoino والعكس صحيح."
        },
        {
          id: "20",
          text: "16- جميع رسائل الإخطار من سوق Excoino يتم إرسالها من الرقم المخصص لـ Excoino 10000007000777 وعنوان البريد الإلكتروني no-reply@excoino.com. الشركة ليست مسؤولة عن الرسائل المرسلة من أرقام أو عناوين بريد إلكتروني غير المذكورة."
        },
        {
          id: "21",
          text: "17- لاستخدام خدمات سوق Excoino، يجب عليك التسجيل وإنشاء حساب مستخدم في Excoino."
        },
        {
          id: "22",
          text: "18- الشركة مخولة بطلب معلومات إضافية من المستخدمين في أي وقت للتحقق من هويتهم ومعاملاتهم. يجب على المستخدمين تقديم المعلومات المطلوبة؛ خلاف ذلك، مخولة الشركة تعليق حساب المستخدم."
        },
        {
          id: "23",
          text: "19- بدخول لوحة سوق Excoino، يوافق المستخدم على الامتثال لجميع قوانين جمهورية إيران الإسلامية، بما في ذلك قانون مكافحة غسل الأموال، أثناء استخدام الخدمات. بخلاف ذلك، فإن المستخدم هو المسؤول الوحيد عن أي مسؤوليات جنائية ومدنية."
        },
        {
          id: "24",
          text: "20- حساب المستخدم شخصي، ومشاركة الحساب مع الآخرين بأي شكل (تأجير الحسابات البنكية، معلومات المستخدم، إلخ) ممنوعة. إذا تم تحديد مثل هذه الحالات، سيتم تعليق حساب المستخدم، وتقع جميع العواقب والمسؤوليات على عاتق المستخدم."
        }
      ]
    },
    {
      id: "4",
      title: "4. التزامات المستخدم",
      desc: [
        {
          id: "1",
          text: "1- يوافق المستخدم على أن مصدر ووجهة جميع العملات المشفرة التي يقدمها في معاملات سوق Excoino تتوافق تمامًا مع اللوائح القانونية وقوانين جمهورية إيران الإسلامية."
        },
        {
          id: "2",
          text: "2- يجب على المستخدم قراءة الرسوم بدقة وأن يكون على علم كامل برسوم المعاملة قبل التداول. لن يتم قبول أي اعتراضات على خصم الرسوم أو عدم معرفة مقدار الرسوم من قبل الشركة، والشركة ليست مسؤولة في هذا الصدد."
        },
        {
          id: "3",
          text: "3- يجب على المستخدم تسجيل طلبه بدقة كاملة. في حالة وجود أي أخطاء مزعومة، لا يمكن إلغاء أو استرداد العملة، والشركة ليست مسؤولة عن أخطاء المستخدم هذه ولا تتحمل أي مسؤولية."
        },
        {
          id: "4",
          text: "4- نظرًا لطبيعة شبكة البلوكشين، كل عنوان محفظة عملة مشفرة فريد لتلك العملة المشفرة. نظرًا لتشابه بعض عناوين العملات المشفرة، إذا تم إيداع عملة مشفرة أخرى بطريق الخطأ إلى العنوان المحدد، فإن المسؤولية الكاملة عن الخطأ تقع على عاتق المستخدم، والشركة ليست مسؤولة عن أخطاء المستخدم."
        },
        {
          id: "5",
          text: "5- يقر ويوافق المستخدم على أنه على علم بمخاطر الاستثمار في مجال العملات الرقمية ويشارك في الاستثمار وتبادل العملات المشفرة مع هذا العلم."
        },
        {
          id: "6",
          text: "6- يجب على المستخدم التأكد من دقة عنوان المحفظة وأي معلومات ذات صلة عند إيداع (شحن) أي عملة إلى محفظته. في حالة الإهمال والإيداع أو الشحن إلى عنوان غير صحيح أو أي مشاكل ذات صلة، أو شبكة مختلفة عن تلك المقدمة من سوق Excoino (شبكة BSC)، فإن الشركة ليست مسؤولة عن أخطاء المستخدم، ويتنازل المستخدم عن أي حق في المطالبة أو الاعتراض بشأن أخطائه الخاصة."
        },
        {
          id: "7",
          text: "7- لضمان أمان ودقة المعاملات، يمنع استخدام أي أدوات غير مصرح بها أو بوتات أو أنشطة تداول مشبوهة حسب ما تحدده سوق Excoino. إذا شارك المستخدم في مثل هذه الأنشطة، فستعتبر جميع المعاملات ذات الصلة غير مقبولة، وستلغى، وتفقد أي تأثير مالي أو قانوني. سيتم استرداد أصول المستخدم الرئيسية فقط، وسيتم تعطيل حساب المستخدم."
        },
        {
          id: "8",
          text: "8- يوافق المستخدم على أن جميع أنشطته وأنشطة الشركة تخضع لقوانين جمهورية إيران الإسلامية وأن أنشطته يجب أن لا تنتهك هذه القوانين. في حالة وجود أي انتهاك أو سوء استخدام مالي أو غير مالي أو أنشطة مشبوهة بأي طريقة، سيتم تعليق حساب المستخدم للمراجعة (48 إلى 72 ساعة عمل على الأقل). إذا تم اكتشاف انتهاك، سيتم تقديم هوية المستخدم والمعلومات المالية إلى السلطات القانونية المختصة، وسيكون المستخدم مسؤولًا عن جميع الأضرار ذات الصلة دون أي شروط أو مطالبات."
        }
      ]
    },
    {
      id: "5",
      title: "5. شروط المعاملة",
      desc: [
        {
          id: "1",
          text: "1- في حالة وجود أي أسباب للغموض أو الشك في المعاملات، أو انتهاكات القوانين أو اللوائح، فإن الشركة مخولة بإلغاء المعاملة دون الرجوع القانوني وإعادة أصول الطرفين. يتنازل المستخدمون عن أي حق في المطالبة أو الاعتراض أو الإحالة إلى السلطات ذات الصلة في هذا الصدد."
        },
        {
          id: "2",
          text: "2- لمنع أي انتهاكات أو مخالفات للوائح المالية، تقع مسؤولية كل معاملة على عاتق المستخدم. يُفترض أن المستخدم يضمن أمان حسابه ولا يشارك معلومات حسابه مع الآخرين."
        },
        {
          id: "3",
          text: "3- سوق Excoino ليست مسؤولة عن الإيداعات/السحوبات من الرموز أو العملات المشفرة على المنصات أو الشبكات غير المدعومة من سوق Excoino. يتحمل المستخدمون وحدهم مسؤولية أي إيداعات/سحوبات غير صحيحة ويتنازلون عن أي حق في المطالبة في هذا الصدد."
        },
        {
          id: "4",
          text: "4- الأوامر المنفذة من قبل سوق Excoino، مع أرقام المعاملات أو التتبع المسجلة، تعتبر مكتملة من قبل سوق Excoino."
        },
        {
          id: "5",
          text: "5- إذا قامت سوق Excoino بائتمان حساب المستخدم عن طريق الخطأ أو بارتكاب أي أخطاء في الحسابات، فإن الشركة مخولة بتصحيح الخطأ في أي وقت دون أي إجراءات إدارية أو قانونية. بقبول هذا المستند، يمنح المستخدم الإذن للشركة لاتخاذ مثل هذه الإجراءات ويتنازل عن أي حق في الاعتراض أو المطالبة فيما يتعلق بعمليات الشركة."
        },
        {
          id: "6",
          text: "6- الرسوم التي تفرضها الشركة نهائية وغير قابلة للاسترداد وغير قابلة للتغيير تحت أي ظرف من الظروف."
        },
        {
          id: "7",
          text: "7- إذا قامت الشركة بائتمان حساب المستخدم عن طريق الخطأ أو بارتكاب أي أخطاء في الحسابات أو الأسعار، يسمح المستخدم للشركة بتصحيح الخطأ في أي وقت دون أي إجراءات إدارية أو قانونية، ودون الحاجة إلى موافقة إضافية من المستخدم. يكون تحديد الخطأ أو الدفع غير المصرح به من قبل الشركة صالحًا، ويتنازل المستخدم عن أي حق في الاعتراض أو المطالبة فيما يتعلق بعمليات الشركة."
        },
        {
          id: "8",
          text: "8- في حالة وجود أي إيداعات/سحوبات/معاملات مشبوهة، سيتم مراجعتها في غضون 48 إلى 72 ساعة عمل."
        },
        {
          id: "9",
          text: "9- بشكل عام، تكون الإيداعات الفورية. عادة ما تكتمل عمليات سحب العملات المشفرة أو التحويلات إلى Excoino في غضون 45 دقيقة بعد تسجيل الطلب خلال ساعات العمل (9-17) وحتى ساعتين خلال ساعات العمل غير الرسمية (17-24) والعطلات الرسمية. ينطبق هذا الجدول الزمني في ظل الظروف العادية، وقد تحدث تأخيرات أثناء أحداث القوة القاهرة أو ارتفاع حجم الطلبات أو تقلبات السوق أو حركة المرور على الشبكة. يحق للمستخدم متابعة دقة المعاملة لكنه لا يمكنه المطالبة بأي تعويضات في مثل هذه الحالات."
        },
        {
          id: "10",
          text: "10- في المعاملة، قد تكون هوية المستلم هي نفسها هوية المرسل. لا تتحقق سوق Excoino من هوية المستلم والمرسل ولا تتحمل أي مسؤولية عن العلاقة بين البائع والمشتري. لا تقوم الشركة بدور الوسيط أو الحكم في مثل هذه الحالات وليست مسؤولة."
        },
        {
          id: "11",
          text: "11- سوق Excoino ليست مسؤولة عن استرداد الرموز، وجميع الأسعار المدرجة في سوق Excoino يتم تسجيلها وإدارتها من قبل مستخدمي السوق. لا يلعب سوق Excoino دورًا في هذا الصدد، ولا يحق للمستخدمين المطالبة."
        },
        {
          id: "12",
          text: "12- المعاملات التي تم تنفيذها بواسطة سوق Excoino أثناء تنفيذ الأمر غير قابلة للتغيير وتخضع لقوانين الشبكة المنفذة للمعاملة."
        },
        {
          id: "13",
          text: "13- لا يمكن إيداع وسحب وتداول العملات الورقية مثل الريال والدولار واليورو وما إلى ذلك في سوق Excoino. يمكن للمستخدمين فقط إيداع وسحب وتداول العملات المشفرة على شبكة BSC في سوق Excoino."
        }
      ]
    },
    {
      id: "6",
      title: "6. المسؤوليات وإخلاء المسؤولية",
      desc: [
        {
          id: "1",
          text: "1- المستخدم مسؤول بالكامل عن فهم سوق العملات المشفرة، والشركة ليست مسؤولة عن قيمة العملات المشفرة وتقلبات السوق."
        },
        {
          id: "2",
          text: "2- أي إهمال من المستخدم في الحفاظ على معلومات الأمان الخاصة بحسابه، بما في ذلك كلمات المرور، هو مسؤولية المستخدم، والشركة ليست مسؤولة في هذا الصدد."
        },
        {
          id: "3",
          text: "3- أي تبعات ضريبية ناتجة عن المعاملات بين المستخدم والشركة أو بين المستخدمين هي مسؤولية المستخدمين، والشركة ليست مسؤولة."
        },
        {
          id: "4",
          text: "4- الشركة ليست مسؤولة عن التأخيرات أو مشاكل التوقيت في الموقع المستهدف أو المعاملات غير الناجحة الناجمة عن أي سبب، بما في ذلك عيوب النظام أو المشكلات أو صيانة نظام العملات المشفرة."
        },
        {
          id: "5",
          text: "5- يوافق المستخدم على أن سوق Excoino مسؤولة فقط عن تحويل المبلغ المحدد في المعاملة المعرفة (أمر الشراء أو البيع) ولا تتحمل أي مسؤولية أخرى."
        },
        {
          id: "6",
          text: "6- الشركة، ضمن نطاق القانون واللوائح الواردة في هذا المستند، ليست ملزمة أو تضمن أي أمور غير محددة في هذا المستند فيما يتعلق بتقديم الخدمات أو قيمة العملات المشفرة غير المقبولة."
        }
      ]
    },
    {
      id: "7",
      title: "7. لوائح إضافية لتعليق الحساب",
      desc: [
        {
          id: "1",
          text: "1- في حالة ملاحظة أي من الحالات التالية بالإضافة إلى الحالات المذكورة أعلاه، سيتم تعليق حساب المستخدم بواسطة سوق Excoino:",
          detail: [
            {
              id: "2",
              text: "تقديم أمر كتابي من المحاكم القضائية أو المؤسسات الحكومية أو السلطات المختصة الأخرى،"
            },
            {
              id: "3",
              text: "عدم الامتثال لقواعد وشروط هذا المستند،"
            },
            {
              id: "4",
              text: "عدم الالتزام بقوانين جمهورية إيران الإسلامية واللوائح والتوجيهات ذات الصلة،"
            },
            {
              id: "5",
              text: "أي وصول غير مصرح به من طرف ثالث إلى الخادم والاستخدام غير القانوني له،"
            },
            {
              id: "6",
              text: "أي تدخل في شبكة البلوكشين للعملات المشفرة بقصد خداع الشبكة أو سوق Excoino."
            }
          ]
        },
        {
          id: "7",
          text: "2- في جميع الحالات المذكورة أعلاه، يحتفظ بحق الشركة في متابعة الانتهاكات القانونية للمستخدم من السلطات المختصة."
        }
      ]
    },
    {
      id: "8",
      title: "8. سياسة الخصوصية",
      desc: [
        {
          id: "1",
          text: "لعرض سياسة الخصوصية، يرجى زيارة <a href=\"https://www.excoino.com/privacy-policy\" target=\"_blank\">القسم ذو الصلة</a>."
        }
      ]
    }
  ]
};
