export default {
  withdraw: "سحب",
  sendTo: "إرسال إلى:",
  excoinoWallet: "محفظة Excoino",
  excoinoTransfer: "التحويل إلى Excoino",
  otherWallets: "محافظ أخرى",
  crypto: "العملة المشفرة",
  noCryptos: "لا توجد عملات مشفرة متاحة",
  selectCoin: "يرجى اختيار العملة المشفرة",
  network: "الشبكة",
  selectNetwork: "يرجى اختيار الشبكة",
  noNetwork: "لا توجد شبكة متاحة",
  address: "العنوان",
  addressList: "قائمة العناوين",
  tag: "الوسم/المذكرة",
  cryptoAmount: "كمية العملة المشفرة",
  availableBalance: "الرصيد المتاح",
  enterCryptoAmount: "يرجى إدخال كمية العملة المشفرة",
  all: "الكل",
  fee: "الرسوم",
  netWithdrawalAmount: "صافي مبلغ السحب",
  pickup: "سحب",
  transfer: "تحويل",
  maxWithdrawCeil: "الحد الأقصى للسحب اليومي:",
  maxWithdrawCeilTip: "الحد الأقصى للسحب اليومي: الحد الأقصى للمبلغ الذي يمكن سحبه يوميًا من سوق Excoino هو {max}.",
  warning1: "يرجى التأكد من دقة عنوان الوجهة على شبكة {network} قبل السحب؛ أي أخطاء أثناء السحب غير قابلة للإلغاء، ولا يمكن استرداد المبلغ المسحوب.",
  warning2: "الحد الأدنى لمبلغ السحب هو {coin} {min}، ولن يتم معالجة السحوبات التي تقل عن هذا المبلغ.",
  warning3: "لأمان تحويلاتك، قم بتغيير كلمة مرور التحويل الآمنة بانتظام.",
  warning4: "ستكتمل عملية التحويل ويتم الإشعار بعد موافقة الخبراء.",
  callSupport: "لا يمكنك طلب سحب، يرجى الاتصال بالدعم!",
  smsVerification: "التحقق عبر الرسائل القصيرة",
  confirmTransfer: "تأكيد التحويل",
  smsCode: "رمز التحقق عبر الرسائل القصيرة",
  withdrawPss: "كلمة مرور السحب الآمنة",
  confirm: "تأكيد",
  cancel: "إلغاء",
  registerToGetCode: "لتلقي رمز التحقق، سجل رقم هاتفك المحمول في حساب المستخدم الخاص بك في Excoino.",
  enterAddress: "يرجى إدخال العنوان",
  invalidAddress: "عنوان غير صالح",
  enterAmount: "يرجى إدخال كمية العملة المشفرة",
  maxWithdrawValidation: "الحد الأقصى التقريبي لمبلغ السحب هو {coin} {maxAmount}",
  minWithdrawValidation: "الحد الأدنى التقريبي لمبلغ السحب هو {coin} {minAmount}",
  insufficientBalance: "رصيد غير كافٍ",
  enterWithdrawPass: "يرجى إدخال كلمة مرور السحب الآمنة",
  getCode: "الحصول على الرمز",
  enterTag: "يرجى إدخال الوسم/المذكرة",
  enterCode: "يرجى إدخال رمز الرسائل القصيرة",
  notAbleToTransfer: "لا يمكن تحويل العملة المشفرة المحددة إلى Excoino.",
  setWithdrawPass: "يرجى تعيين كلمة مرور السحب الآمنة",
  title: "العنوان",
  enterTitle: "يرجى إدخال العنوان",
  invalidPhoneNumber: "تنسيق رقم الهاتف غير صالح. يرجى إعادة الإدخال.",
  invalidCode: "رمز التحقق غير صالح",
  invalidEmail: "تنسيق البريد الإلكتروني غير صالح، يرجى إعادة الإدخال",
  successSave: "تم الحفظ بنجاح",
  operation: "عملية",
  delete: "حذف",
  withdrawTime: "وقت السحب",
  amount: "المبلغ",
  netAmount: "صافي مبلغ السحب",
  trxHash: "هاش المعاملة",
  status: "الحالة",
  refCode: "رمز المرجع",
  withdrawType: "نوع السحب",
  transferToExcoino: "التحويل من السوق إلى Excoino",
  backToWithdrawPage: "العودة إلى صفحة السحب",
  submit: "إرسال",
  addressVerificationCode: "رمز التحقق من العنوان",
  save: "حفظ",
  approvedTransfer: "هل أنت متأكد أنك تريد التحويل؟"
};
