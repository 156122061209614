import { icons } from "../IconComponents/index";
export default {
  name: "base-icon",
  components: {},
  props: {
    name: {
      type: String,
      required: true,
      validation(value) {
        return Object.prototype.hasOwnProperty.call(icons, value);
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    iconComponent() {
      return icons[this.name];
    },
  },
  mounted() {},
  methods: {},
};
