export default {
  name: "terms-of-use-modal",
  components: {},
  props: {
    showModal: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
