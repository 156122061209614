export class CustomHttpError extends Error {
  constructor(code = "", ...params) {
    super(...params);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomHttpError);
    }
    this.name = "CustomHttpError";
    this.code = code;
    this.date = new Date();
  }
}
