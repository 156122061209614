import qs from "qs";

export const appendToFormData = (obj) => {
  let formData = new FormData();
  Object.keys(obj).forEach((key) => {
    formData.append(key, obj[key]);
  });
  return formData;
};

export const appendParam = (param) => {
  return param === undefined ? "" : `/${param}`;
};

export const appendQueryString = (params) => {
  return "?" + qs.stringify(params);
};
