import BaseIcon from "../../UI/BaseIcon/index";
import {mapActions} from "vuex";
export default {
  name: "account-info",
  components: { BaseIcon },
  props: [],
  data() {
    return {
      value: false,
      totalUSDT: null,
      userData:{},
      drawerStyles: {
        height: "98vh",
        width:"335px",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
        left:"0",
        backgroundColor: "#141e2c",
        borderRadius: '12px'
      },
      drawerWidth: "335px",
      baseUrl: process.env.VUE_APP_EXCOINO_PANEL_URL,
    };
  },
  computed: {},
  mounted() {
    this.getTotalAsset()
    this.getUserData()
  },
  methods: {
    ...mapActions({
      getWallet: "userCenter/WALLET_SERVICE_V2",
      getMemberData: "userCenter/GET_MEMBER_DATA_SERVICE",
    }),
    getUserData() {
      this.getMemberData().then((response) => {
        if (response.data.code === 0) {
          this.userData = response.data.data;
        }
      });
    },
    getTotalAsset() {
      this.getWallet().then(response => {
        this.totalUSDT = response.data.data.totalUsdBalance
      })
    },
    onClickLogout() {
      this.$emit("onLogout");
    },
  },
};
