import dayjs from "dayjs";
import jalaliday from "jalaliday";
import momentJalali from "moment-jalaali"
import relativeTime from "dayjs/plugin/relativeTime";
// dayjs.extend(utc);
// dayjs.extend(tz);
if (localStorage.getItem("lang") === "fa") {
dayjs.extend(jalaliday);
dayjs.extend(relativeTime);
dayjs.calendar("jalali").locale("fa");
}

export default dayjs;
