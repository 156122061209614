export default {
    introduceToFriends: "Introduce to Friends",
    receivedGiftsValue: "Value of Received Gifts",
    totalInvited: "Total Invited",
    person: "Person",
    affiliateCode: "Affiliate Code",
    affiliateLink: "Affiliate Link",
    copySuccess: "Address successfully copied",
    inviteFriends: "Invite your friends to Excoino Market and earn rewards.",
    username: "Invitee's Name (Username)",
    registerTime: "Registration Date",
    giftAmount: "Received Gift Amount",
    more: "Details",
    action: "Action",
    rewardsDetails: "Gift Details",
    rewardAmount: "Gift Value",
    rewardCount: "Number of Gifts",
    successfulCopyRefCode: "Affiliate code successfully copied",
    successfulCopyRefLink: "Affiliate link successfully copied",
    qrCodeAddress: "QRCode Affiliate Code"
  };
  