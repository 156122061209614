export default {
    title: "Markets",
    loginToAddFavorites: "Log in to Excoino to add favorites",
    addToFavorites: "Add to Favorites",
    removeFavorites: "Remove Favorites",
    custom: "Favorites",
    coin: "Pair",
    symbol: "Trade Pair",
    favor: "Favorites",
    newPrice: "Latest Price",
    exchangeNum: "Trade Volume",
    change: "Daily Change",
    priceTrend: "Price Trend",
    operate: "Operation",
    trading: "Trading",
    high: "Highest Price",
    low: "Lowest Price",
    demoMarket: "Demo Market"
  };
  