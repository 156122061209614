//Division function, used to get the exact division result
//Note: There will be errors in the division result of javascript, which will be more obvious when dividing two floating-point numbers. This function returns a more accurate division result.
//Call: accDiv(arg 1,arg 2)
//Return value: the exact result of dividing arg 1 by arg 2
function accDiv(arg1, arg2) {
  var t1 = 0,
    t2 = 0,
    r1,
    r2;
  try {
    t1 = arg1.toString().split(".")[1].length;
  } catch (e) {}
  try {
    t2 = arg2.toString().split(".")[1].length;
  } catch (e) {}
  // with (Math) {
  //     r1 = Number(arg1.toString().replace(".", ""))
  //     r2 = Number(arg2.toString().replace(".", ""))
  //     return (r1 / r2) * pow(10, t2 - t1);
  // }
  r1 = Number(arg1.toString().replace(".", ""));
  r2 = Number(arg2.toString().replace(".", ""));
  return (r1 / r2) * Math.pow(10, t2 - t1);
}

//Adding a div method to the Number type makes it more convenient to call.
Number.prototype.div = function (arg) {
  return accDiv(this, arg);
};
String.prototype.div = function (arg) {
  return accDiv(this, arg);
};

//Multiplication function, used to get the exact multiplication result
//Note: There will be errors in the multiplication result of javascript, which will be more obvious when multiplying two floating-point numbers.
// This function returns a more accurate multiplication result.
//Call: accMul(arg 1,arg 2)
//Return value: the exact result of arg1 multiplied by arg2
function accMul(arg1, arg2) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
}

// Add a mul method to the Number type, which is more convenient to call.
Number.prototype.mul = function (arg) {
  return accMul(arg, this);
};
String.prototype.mul = function (arg) {
  return accMul(arg, this);
};

export default {
  accDiv,
  accMul,
};
