export default {
    openOrders: "Open Orders",
    stopLimitOrders: "Stop/Limit Orders",
    pair: "Currency Pair",
    price: "Price",
    cancelOrder: "Cancel Order",
    orderType: "Order Type",
    sell: "Sell",
    buy: "Buy",
    orderDate: "Order Date",
    activationPrice: "Activation Price",
    details: "Details",
    stopLimit: "Stop/Limit",
    limitOrder: "Limit",
    marketOrder: "Market",
    limit: "Limit",
    ocoLimit: "OCO Limit",
    ocoStopLimit: "OCO Stop/Limit",
    marketWallet: "Market Wallet",
    transferToExcoino: "Transfer to Excoino",
    excoinoWallet: "Excoino Wallet",
    transferToMarket: "Transfer to Market",
    marketTip: "This amount indicates the total value of your wallet in the Excoino Market section. (Usable in Excoino trading market)",
    viewWallet: "View Wallet",
    excoinoTip: "This amount indicates the total value of your wallet in the Excoino section. (Usable in Excoino exchange)",
    walletsTip: "You have two separate wallets for the Excoino (OTC) and Market (Trading) sections. To use the Market features, you need to transfer your desired assets from the Excoino wallet to the Market.",
    noMarketWallets: "Your Market wallet is empty.<br>To transfer assets to the Market, use the Excoino wallet.",
    allMarketAssets: "View All Market Assets",
    largestVolume: "Largest Trading Volume",
    highestGrowthOfTheDay: "Highest Growth of the Day",
    biggestDropOfTheDay: "Biggest Drop of the Day",
    newestMarkets: "Newest Trading Markets",
    favoriteMarkets: "Favorite Markets",
    noOrders: "You currently have no open orders.",
    markets: "Markets",
    allMarkets: "All Markets",
    noData: "No data available to display",
    orderCanceled: "Order Canceled",
    marketTransferDesc: "To transfer assets from Excoino to the Market, use the Excoino Wallet page",
    goToExcoinoWallet: "Go to Excoino Wallet",
    transferFromExcoino: "Transfer {unit} from Excoino",
    viewExcoinoWallet: "View Excoino Wallet",
    excoinoWalletDesc: "To view Excoino assets, go to the Excoino Wallet page."
  };
  