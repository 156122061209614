export default {
  member: null,
  activeNav: "",
  lang: "",
  exchangeSkin: "night",
  loginTimes: null,
  depositStatus: null,
  withdrawStatus: null,
  transferStatus: null,
  responseStatusCode: "",
  tradingStatus: "",
  showTermsOfUseModal: false,
};
