export default {
  addFavorError: "لا يمكن الإضافة إلى المفضلة",
  addFavorWarning: "تحتاج إلى تسجيل الدخول من خلال Excoino لإضافة المفضلة",
  newPrice: "أحدث سعر",
  change: "التغيير اليومي",
  high: "أعلى سعر",
  low: "أدنى سعر",
  exchangeNum: "الحجم",
  toSubmitOrder: "لوضع طلب في سوق Excoino",
  loginToExcoinoMarket: "تسجيل الدخول",
  or: "أو",
  createNewAccount: "إنشاء حساب جديد",
  excoinoMarketChart: "رسم بياني لسوق Excoino",
  globalChart: "الرسم البياني العالمي",
  sell: "بيع",
  buy: "شراء",
  price: "السعر",
  amount: "الكمية",
  transfer: "تحويل",
  balanceIncrease: "زيادة الرصيد",
  openOrders: "الأوامر المفتوحة",
  stopLimit: "حد/إيقاف",
  limitOrder: "أمر حد",
  marketOrder: "أمر سوق",
  limit: "حد",
  ocoLimit: "حد OCO",
  ocoStopLimit: "إيقاف/حد OCO",
  turnover: "حجم التداول",
  type: "نوع",
  pairCoin: "زوج",
  direction: "اتجاه",
  activationPrice: "سعر التفعيل",
  description: "الوصف",
  cancelOrder: "إلغاء الأمر",
  cancelOrderConfirmation: "هل أنت متأكد أنك تريد إلغاء الأمر؟",
  confirm: "تأكيد",
  cancel: "إلغاء",
  ordersHistory: "تاريخ الأوامر",
  orderDetails: "تفاصيل الأمر",
  refCode: "رمز المرجع",
  coin: "العملة المشفرة",
  symbol: "زوج",
  lastPrice: "آخر سعر",
  dayChange: "التغيير اليومي",
  percent: "النسبة المتداولة",
  market: "الأسواق",
  favorite: "المفضلة",
  do_favorite: "أضف إلى المفضلة",
  cancel_favorite: "إلغاء المفضلة",
  orderType: "نوع الأمر",
  dealAmount: "حجم التداول",
  vol: "حجم 24 ساعة",
  limited_price: "سعر الأمر المحدد",
  market_price: "سعر السوق",
  stop_order_price: "سعر أمر الإيقاف",
  fees_rate: "معدل الرسوم",
  balance: "الأرصدة",
  canUse: "المتاح",
  recharge: "إيداع",
  buyPrice: "سعر الشراء",
  buyAmount: "كمية الشراء",
  averageTradePrice: "متوسط سعر التداول",
  buyTip: "سعر السوق",
  sellPrice: "سعر البيع",
  stopPrice: "سعر الإيقاف",
  sellAmount: "كمية البيع",
  sellTip: "سعر السوق",
  stopLimitDelegation: "إيقاف/حد",
  preconditionOrdersHistory: "تاريخ أوامر الحد/الإيقاف",
  num: "الكمية",
  fee: "الرسوم",
  buySell: "شراء/بيع",
  time: "الوقت",
  stall: "موقوف",
  total: "الإجمالي",
  traded: "الكمية المتداولة",
  action: "الإجراء",
  undo: "إلغاء",
  more: "تفاصيل",
  done: "الكمية المتداولة",
  status: "الحالة",
  finished: "مكتمل",
  canceled: "ملغى",
  expired: "منتهي الصلاحية",
  parted: "مجزأ",
  ordered: "تم الطلب",
  enterBuyAmount: "يرجى إدخال كمية الشراء",
  success: "عملية ناجحة!",
  enterSellAmount: "يرجى إدخال كمية البيع",
  successCancel: "تم إلغاء الأمر بنجاح",
  marketPrice: "سعر السوق",
  history: "التاريخ",
  preconditionOrders: "أوامر الحد/الإيقاف",
  orders: "الأوامر",
  demoMarket: "سوق تجريبي",
  demoTrading: "تداول تجريبي",
  modalText01: "سوق العرض التجريبي هو سوق تعليمي واختباري يسمح لك بالتداول بالعملات الافتراضية مثل V-USDT، V-BTC، و V-ETH. هذه العملات مخصصة فقط للتجربة والتعلم ولا تحمل أي قيمة حقيقية. لا يمكنك تحويلها إلى ريال أو دولار أو سحبها من النظام. يمكنك فقط تبادلها في سوق العرض التجريبي مع بعضها البعض.",
  modalText02: "يحصل جميع المستخدمين على بعض من V-USDT مجانًا بعد التسجيل. يمكنك استخدام هذه العملة لوضع أوامر بيع أو شراء لعملات أخرى في سوق العرض التجريبي. على سبيل المثال، يمكنك تحويل V-USDT إلى V-BTC. للقيام بذلك، تحتاج إلى إدخال السعر والكمية المطلوبة ثم تأكيد طلبك. سيقوم النظام تلقائيًا بمطابقة طلبك مع الطلبات المناسبة من المستخدمين الآخرين وتنفيذ الصفقة.",
  modalText03: "يمكنك استخدام هذا السوق لتتعرف على إمكانيات النظام بدون المخاطرة بأصولك الحقيقية ثم الانتقال إلى التداول الحقيقي.",
  expand: {
    time: "الوقت",
    price: "السعر",
    amount: "الكمية",
    fee: "الرسوم",
    volume: "حجم التداول"
  },
  day: "يوم",
  hours: "ساعات",
  minutes: "دقائق",
  seconds: "ثواني",
  noOrders: "لا توجد أوامر",
  realtime: "الوقت الحقيقي",
  kline: "الرسم البياني",
  depth: "مخطط العمق",
  publishState0: "على وشك البدء. يرجى الانتظار...",
  publishState1: "على وشك الانتهاء. يرجى الانتظار...",
  publishState2: "جاري دمج الأوامر، يرجى الانتظار...",
  publishState3: "تم الدمج، يمكنك التداول الآن...",
  publishCountTxt0: "يبدأ بعد",
  publishCountTxt1: "ينتهي بعد",
  publishCountTxt2: "الوقت المتبقي لبدء التداول",
  publishCountTxt3: "تم الانتهاء من النشاط!",
  insufficientBalance: "رصيد محفظتك غير كافٍ",
  limitOrderPrice: "سعر الأمر المحدد",
  enterLimitOrderPrice: "يرجى إدخال سعر الأمر المحدد.",
  enterActivationPrice: "يرجى إدخال سعر التفعيل.",
  enterBuyPrice: "يرجى إدخال سعر الشراء.",
  enterSellPrice: "يرجى إدخال سعر البيع.",
  ocoOrderInfoModal: {
    title: "كيفية استخدام ميزة OCO",
    buyOrder: "أمر شراء",
    sellOrder: "أمر بيع",
    ocoTrades: "تداولات OCO: هذا يعني أنه يمكنك وضع أمرين في نفس الوقت أثناء التداول، أحدهما كأمر إيقاف والآخر كأمر حد.",
    buyTrades: "تداولات الشراء:",
    buyStop: "وضع الإيقاف: في هذا الوضع، يتم وضع أمر الإيقاف فوق سعر السوق الحالي.",
    buyLimit: "وضع الحد: في هذا الوضع، يتم وضع أمر الحد تحت سعر السوق الحالي.",
    desc1: "يجب أن يكون سعر الإيقاف في تداول الشراء (النقطة B) أعلى من سعر السوق الحالي لأمر الشراء، ويجب أن يكون سعر الحد (النقطة C) أقل من سعر السوق الحالي.",
    notice: "ملاحظة:",
    desc2: "يعني أمر الإيقاف أنه عندما يصل السعر إلى النقطة B (3000)، يتم تفعيل الأمر، وفي نفس الوقت يتم إلغاء أمر الحد. على العكس، إذا انخفض السعر إلى أو تحت 1500 (النقطة C)، يتم تفعيل أمر الحد، ويتم إلغاء أمر الإيقاف.",
    desc3: "تداولات OCO: هذا يعني أنه يمكنك وضع أمرين في نفس الوقت أثناء التداول، أحدهما كأمر إيقاف والآخر كأمر حد.",
    sellTrades: "تداولات البيع:",
    sellStop: "وضع الإيقاف: في هذا الوضع، يتم وضع أمر الإيقاف تحت سعر السوق الحالي.",
    sellLimit: "وضع الحد: في هذا الوضع، يتم وضع أمر الحد فوق سعر السوق الحالي.",
    desc4: "يجب أن يكون سعر الإيقاف في تداول البيع (النقطة C) تحت سعر السوق الحالي لأمر البيع، ويجب أن يكون سعر الحد (النقطة B) فوق سعر السوق الحالي.",
    desc5: "يعني أمر الإيقاف أنه عندما يصل السعر إلى النقطة C (1500)، يتم تفعيل الأمر، وفي نفس الوقت يتم إلغاء أمر الحد. على العكس، إذا ارتفع السعر إلى أو فوق 3000 (النقطة B)، يتم تفعيل أمر الحد، ويتم إلغاء أمر الإيقاف.",
    understood: "فهمت"
  },
  preconditionOrderInfoModal: {
    title: "كيفية استخدام ميزة الحد/الإيقاف",
    buyOrder: "أمر شراء",
    sellOrder: "أمر بيع",
    desc1: "سعر العملة المطلوبة في النقطة A، وإذا وصل إلى النقطة C، سيتم تنفيذ أمر الشراء الخاص بك، مما يتيح لك الحصول على كمية أكبر من العملة المطلوبة مقارنة بالنقطة A، مما يؤدي إلى ربح. على العكس، إذا وصل السعر إلى النقطة B، سيتم تنفيذ أمر الشراء الخاص بك، مما يمنعك من الشراء بأسعار أعلى وتكبد المزيد من الخسارة.",
    desc2: "ملاحظة مهمة: نقطة التفعيل هي الشرط المسبق لتنفيذ الصفقة، وبعد أن يصل السعر إلى نقطة التفعيل، يكون الشرط الثاني، وهو الوصول إلى سعر التداول (B أو C)، ضروريًا لتنفيذ الصفقة. يمكن أن تكون نقاط التفعيل (الخطوط المتقطعة) أعلى أو أقل من سعر التداول. تأكد من اختيار نقطة التفعيل بعناية، حيث يمكن أن يصل السعر إلى نقطة التفعيل ولكن ليس إلى سعر التداول، مما يمنع تنفيذ الصفقة.",
    desc3: "* يجب إنشاء أوامر منفصلة لكل من أوامر B أو C.",
    desc4: "سعر شراء عملتك في النقطة A، وإذا وصل إلى النقطة B، سيكون أمر البيع الخاص بك مربحًا. على العكس، إذا وصل السعر إلى النقطة C، سيتم تنفيذ أمر البيع الخاص بك، مما يمنع المزيد من الخسارة.",
    desc5: "ملاحظة مهمة: نقطة التفعيل هي الشرط المسبق لتنفيذ الصفقة، وبعد أن يصل السعر إلى نقطة التفعيل، يكون الشرط الثاني، وهو الوصول إلى سعر التداول (B أو C)، ضروريًا لتنفيذ الصفقة. يمكن أن تكون نقاط التفعيل (الخطوط المتقطعة) أعلى أو أقل من سعر التداول. تأكد من اختيار نقطة التفعيل بعناية، حيث يمكن أن يصل السعر إلى نقطة التفعيل ولكن ليس إلى سعر التداول، مما يمنع تنفيذ الصفقة.",
    desc6: "* يجب إنشاء أوامر منفصلة لكل من أوامر B أو C.",
    understood: "فهمت"
  }
};
