export default {
  dashboard: "لوحة التحكم",
  exchange: "تداول السوق",
  markets: "الأسواق",
  assets: "الأصول",
  transactions: "المعاملات",
  deposit: "إيداع",
  transfer: "تحويل",
  withdraw: "سحب",
  orders: "الأوامر",
  openOrders: "الأوامر المفتوحة",
  history: "التاريخ",
  ordersHistory: "تاريخ الأوامر",
  preconditionOrdersHistory: "تاريخ أوامر الحد/الإيقاف",
  stopLimitOrders: "حد/إيقاف",
  guide: "دليل",
  excoino: "الانتقال إلى الصفحة الرئيسية لـ Excoino",
  excoinoPanel: "العودة إلى Excoino",
  userProfile: "ملف المستخدم",
  affiliateProfile: "إحالة الأصدقاء",
  support: "الدعم",
  logout: "تسجيل الخروج",
  register: "تسجيل",
  userCenter: "مركز المستخدم",
  helpCenter: "مركز الدعم",
  loginRegister: "تسجيل الدخول/التسجيل",
  staking: "Staking",
  activeLanguage: "اللغات النشطة في Excoino"
};
