export default {
    pagesTitle: "Excoino | Currency strict | Excoino official website-global bitcoin trading platform | Global digital currency trading platform",
    loginTip: "لطفا ابتدا وارد شوید",
    searchPlaceHolder: "جستجو ...",
    logout: "خروج",
    tip: "نکته‌های مهم:",
    nodata: "داده ای ثبت نشده است",
    more: "همه",
    please: "لطفا",
    pleaseSelect: "انتخاب کنید",
    loadingText: "در حال بارگذاری نتایج، لطفا صبر کنید",
    getRealized:"متوجه شدم",
    excoino:"اکسکوینو",
    messages: "پیام ها",
    noMessage: "شما پیامی ندارید"
}
