import Vue from "vue";
import axios from "axios";
import VueCookies from "vue-cookies";
import { CustomHttpError } from "./CustomHTTPError";

Vue.use(VueCookies);

const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  //   baseURL: "https://market-api.excoino.com",
  headers: {
    // 'Content-Type': 'application/json;charset=utf-8',
    // 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    "Accept-Language": localStorage.getItem("lang"),
  },
});

// VueCookies.set(
//   "accessToken",
//   "eyJhbGciOiJSUzUxMiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2Rm1GRDFlV2dLWGhFWFpEbFpHdGgtNktxLWd6d3lKY3N3d0JjYUl3bFAwIn0.eyJleHAiOjE2OTIxOTE5NTIsImlhdCI6MTY5MjE4ODM1MiwianRpIjoiMzA4ZTBkMzItM2EyYS00MTliLTgxMGYtZjY5ODE4MTcxOWUzIiwiaXNzIjoiaHR0cDovLzE5Mi4xNjguMjEuMjE3L2F1dGgvcmVhbG1zL1Nzb1VzZXJDb3JlIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6Ijk3MjhiZmIxLWM0ZDctNDZiMC04M2Y5LTRmMDg3YTdlY2VjZCIsInR5cCI6IkJlYXJlciIsImF6cCI6IkV4Y29pbm8iLCJzZXNzaW9uX3N0YXRlIjoiNTkxZTRiODEtMmZiYi00ZDk0LTliMDktZGRiYjk2ZDI5Y2U2IiwiYWNyIjoiMSIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ1c2VyXzExIn0.O7dtyJGtAmXWThRnGqdPTkglkbHUr-gUpxweVXmo8YmwR337I4OpFdIxLo3JxBTU_vCwUVeMLWGN5PoM-0w-gI5-R6oj6PCx3ESowsExScDUVYAaqVqwbah7al5HF3wW7YJF-K6SxAZ1RF-rjOIjEldcPQAUbVxCB0gp9socMXDJTXpwgtIS_VWf3e_f_tdmNOUBpd1dIpmHKFCwM8qWnIlNdDMLE_Aj9_UB65G1MJQRIN0x9RwvlJj0wZ29-ENN-BiKzP6EUYls54pAcG2i_gEYJYh83PPxHV4KXxL_gg8fK9Sa4Cgc7eqdhVRY8_Gbfl645XJlRsm09D7ce8-qvQ",
//   null,
//   null,
//   "localhost"
// );

$axios.interceptors.request.use(
  (config) => {
    const token = VueCookies.get("accessToken", null, "localhost");
    const xAuthToken = localStorage.getItem("TOKEN");
    config.headers["x-auth-token"] = xAuthToken;
    if (token) {
      config.headers["authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
$axios.interceptors.response.use(
  function (response) {
    const xAuthToken = response.headers["x-auth-token"];
    if (response.data.code === 0 && xAuthToken != null && xAuthToken !== "") {
      localStorage.setItem("TOKEN", xAuthToken);
    }
    if (
      response.data.code === 401 ||
      response.data.code === 500 ||
      response.data.code === 403
    ) {
      try {
        // throw new CustomHttpError(response.data.code, `Error: Request failed with status code ${response.data.code}: ${response.data.message}`)
        throw new CustomHttpError(response.data.code, response.data.message);
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return response;
  },
  function (error) {
    // if (error && error.response.config.method !== "get") {
    //   Vue.$toast.clear();
    //   Vue.$toast.error(error.response.data.message);
    // }
    return Promise.reject(error.response);
  }
);

export default $axios;
