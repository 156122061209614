export default {
    introduceToFriends: "معرفی به دوستان",
    receivedGiftsValue: "ارزش هدایای دریافتی",
    totalInvited: "مجموع دعوت شدگان",
    person: "نفر",
    affiliateCode: "کد معرف",
    affiliateLink: "لینک معرف",
    copySuccess: "آدرس با موفقیت کپی شد",
    inviteFriends: "دوستان خود را به اکسکوینو مارکت دعوت کرده و پاداش بگیرید.",
    username: "نام معرفی شونده (نام کاربری)",
    registerTime: "تاریخ ثبت نام",
    giftAmount: "مقدار جایزه دریافتی",
    more: "جزئیات",
    action: "عملیات",
    rewardsDetails: "جزئیات هدایا",
    rewardAmount: "ارزش هدیه بدست آمده",
    rewardCount: "تعداد هدیه ها",
    successfulCopyRefCode: "کد معرف با موفقیت کپی شد",
    successfulCopyRefLink: "لینک معرف با موفقیت کپی شد",
    qrCodeAddress: "QRCode کد معرف"
}