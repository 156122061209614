export default {
  openOrders: "الأوامر المفتوحة",
  stopLimitOrders: "أوامر الحد/الإيقاف",
  pair: "زوج العملات",
  price: "السعر",
  cancelOrder: "إلغاء الأمر",
  orderType: "نوع الأمر",
  sell: "بيع",
  buy: "شراء",
  orderDate: "تاريخ الأمر",
  activationPrice: "سعر التفعيل",
  details: "التفاصيل",
  stopLimit: "حد/إيقاف",
  limitOrder: "حد",
  marketOrder: "سوق",
  limit: "حد",
  ocoLimit: "حد OCO",
  ocoStopLimit: "إيقاف/حد OCO",
  marketWallet: "محفظة السوق",
  transferToExcoino: "التحويل إلى Excoino",
  excoinoWallet: "محفظة Excoino",
  transferToMarket: "التحويل إلى السوق",
  marketTip: "تشير هذه القيمة إلى القيمة الإجمالية لمحفظتك في قسم سوق Excoino. (قابل للاستخدام في سوق التداول على Excoino)",
  viewWallet: "عرض المحفظة",
  excoinoTip: "تشير هذه القيمة إلى القيمة الإجمالية لمحفظتك في قسم Excoino. (قابل للاستخدام في سوق التداول على Excoino)",
  walletsTip: "لديك محفظتان منفصلتان لأقسام Excoino (OTC) والسوق (التداول). لاستخدام ميزات السوق، تحتاج إلى تحويل الأصول المطلوبة من محفظة Excoino إلى السوق.",
  noMarketWallets: "محفظة السوق الخاصة بك فارغة.<br>لاستخدام الأصول في السوق، استخدم محفظة Excoino.",
  allMarketAssets: "عرض جميع الأصول السوقية",
  largestVolume: "أكبر حجم تداول",
  highestGrowthOfTheDay: "أعلى نمو خلال اليوم",
  biggestDropOfTheDay: "أكبر انخفاض خلال اليوم",
  newestMarkets: "أحدث الأسواق",
  favoriteMarkets: "الأسواق المفضلة",
  noOrders: "ليس لديك أي أوامر مفتوحة حاليًا.",
  markets: "الأسواق",
  allMarkets: "جميع الأسواق",
  noData: "لا توجد بيانات متاحة للعرض",
  orderCanceled: "تم إلغاء الأمر",
  marketTransferDesc: "لنقل الأصول من Excoino إلى السوق، استخدم صفحة محفظة Excoino",
  goToExcoinoWallet: "اذهب إلى محفظة Excoino",
  transferFromExcoino: "تحويل {unit} من Excoino",
  viewExcoinoWallet: "عرض محفظة Excoino",
  excoinoWalletDesc: "لعرض أصول Excoino، انتقل إلى صفحة محفظة Excoino."
};
