import Vue from "vue";
import Vuex from "vuex";

import global from "./modules/global/index";
import market from "./modules/market/index";
import affiliate from "./modules/affiliate/index";
import otc from "./modules/otc/index";
import userCenter from "./modules/userCenter/index";
import exchange from "./modules/exchange/index";
import tradingView from "./modules/tradingView/index";
import staking from "./modules/staking/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    global,
    market,
    affiliate,
    userCenter,
    otc,
    exchange,
    tradingView,
    staking
  },
});
