import $axios from "../../../utiles/axios";

export default {
  GET_COIN_SERVICE: () => {
    return $axios.post("/otc/coin/all");
  },
  GET_ADVERTISE_SERVICE: () => {
    return $axios.post("/otc/advertise/page-by-unit");
  },
  CREATE_AD_SERVICE: () => {
    return $axios.post("/uc/ad/create");
  },
  GET_AD_DETAIL_SERVICE: () => {
    return $axios.post("/otc/advertise/detail");
  },
};
