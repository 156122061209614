import {mapGetters} from "vuex";

export default {
    name: "header-nav",
    components: {},
    props: {
        isWithdrawActive: {
            type: Boolean,
        },
        // isDepositActive: {
        //   type: Boolean,
        // },
        activeLinkName: {
            type: String,
        },
    },
    computed: {
        ...mapGetters({
            getLoginStatus: "global/IS_LOGIN",
        }),
        isLogin() {
            return this.getLoginStatus;
        },
    },
}
;
