import Vue from "vue";
import dayjs from "../plugins/dayjs";
import {checkDecimalLength} from "./numericValidations"

const momentJalali = require('moment-jalaali')
Vue.filter("timeFormat", (time) => {
    return dayjs(time).format("HH:mm:ss");
});

Vue.filter("dateFormat", (date, getHour = true) => {
    return localStorage.getItem("lang") === "fa" ?
        momentJalali(date).format(getHour ? "jYYYY/jMM/jDD HH:mm:ss" : "jYYYY/jMM/jDD") :
        dayjs(date).format(getHour ? "YYYY/MM/DD HH:mm:ss" : "YYYY/MM/DD");
});

Vue.filter("toFixed", function (number, scale) {
    return new Number(number).toFixed(scale);
});

Vue.filter("toPercent", function (point) {
    var str = Number(point * 100).toFixed(1);
    str += "%";
    return str;
});

function toFloor(number, scale = 8) {
    if (new Number(number) == 0) {
        return 0;
    }
    let __str = number + "";
    if (__str.indexOf("e") > -1 || __str.indexOf("E") > -1) {
        let __num = new Number(number).toFixed(scale + 1),
            __str = __num + "";
        return __str.substring(0, __str.length - 1);
    } else if (__str.indexOf(".") > -1) {
        if (scale == 0) {
            return __str.substring(0, __str.indexOf("."));
        }
        return __str.substring(0, __str.indexOf(".") + scale + 1);
    } else {
        return __str;
    }
}

function compare(a, b) {
    const numA = !isNaN(a);
    const numB = !isNaN(b);

    if (numA && numB) {
        return Number(a) - Number(b);
    }

    if (numA) return -1;
    if (numB) return 1;

    // check for wordhood
    const wordA = /^[a-zA-Z]+$/.test(a);
    const wordB = /^[a-zA-Z]+$/.test(b);

    if (wordA && wordB) {
        return a.localeCompare(b);
    }

    if (wordA) return -1;
    if (wordB) return 1;

    return 1; // or whatever logic to sort within non-alphanumeric values
}

Vue.filter("compare", (a, b) => {
    return compare(a, b);
});

Vue.filter("toFloor", (number, scale) => {
    return toFloor(number, scale);
});

// NUMBER WITH COMMAS
Vue.filter("numberWithCommas", (x, exponent) => {
    return numberWithCommas(x, exponent);
});

export const numberWithCommas = (numb, scale) => {
    // let number = scale >= 0 ? parseFloat(n).toFixed(scale) : n;
    let number = numb;
    if (numb.toString().includes('e')) number = parseFloat(numb).toFixed(scale);
    number = number && number.toString().split(".")[1] ? checkDecimalLength(number, scale) : number;
    let x = number.toString().split(".");
    let x1 = x[0];
    let x2 = x.length > 1 ? "." + x[1] : "";
    let rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
}

Vue.prototype.toFloor = toFloor;
Vue.prototype.numberWithCommas = numberWithCommas;
Vue.prototype.compare = compare;
