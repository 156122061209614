export default {
  recharge: "إيداع",
  symbol: "العملة المشفرة",
  coin: "العملة",
  network: "الشبكة",
  address: "عنوان الإيداع",
  copy: "نسخ العنوان",
  qrCode: "مسح كود QR",
  contactSupport: "لا يمكنك طلب إيداع، يرجى الاتصال بالدعم!",
  successDeposit: "لإيداع {coin} بنجاح، أدخل عنوان {coin} والوسم/المذكرة.",
  confirm: "فهمت",
  walletAddress: "عنوان محفظة {coin} ({network})",
  msg1: "إيداع العملات الورقية غير ممكن.",
  msg2: "تستخدم جميع العملات المشفرة على شبكة BSC نفس عنوان الإيداع. لا يُسمح باستخدام هذا العنوان لإيداع عملات أخرى غير {coin}. إذا تم إيداع عملات أخرى في هذا العنوان، فلن يتم استرداد المبلغ المودع.",
  msg3: "الحد الأدنى لمبلغ الإيداع هو {charge} {coin}، ولن يتم معالجة الإيداعات التي تقل عن هذا المبلغ.",
  msg4: "لا يتغير عنوان إيداعك بشكل متكرر، ويمكنك تكرار الإيداعات. إذا تغير، سنحاول إبلاغك عبر إعلان الموقع الإلكتروني أو البريد الإلكتروني.",
  msg5: "يرجى ضمان أمان جهاز الكمبيوتر والمتصفح الخاص بك لمنع العبث أو تسرب البيانات.",
  msg6: "تأكد من أنها عملة {coin}.",
  msg7: "تأكد من أنها شبكة {network}.",
  record: "معلومات الإيداع",
  successCopy: "تم نسخ العنوان بنجاح.",
  errCopy: "خطأ! يرجى نسخ العنوان يدويًا.",
  time: "وقت الإيداع",
  amount: "المبلغ",
  getAddress: "الحصول على العنوان",
  memoTips: "يرجى إدخال هذه المذكرة عند إعادة الشحن؛ وإلا، ستفقد أصولك.",
  selectCoin: "يرجى اختيار عملة",
  selectNetwork: "يرجى اختيار شبكة",
  tagNotice: "الوسم/المذكرة والعنوان مطلوبان لإيداع {iso} بنجاح."
};
