export default {
    addFavorError: "Cannot add to favorites",
    addFavorWarning: "You need to log in through Excoino to add favorites",
    newPrice: "Latest Price",
    change: "Daily Change",
    high: "Highest Price",
    low: "Lowest Price",
    exchangeNum: "Volume",
    toSubmitOrder: "To place an order on Excoino Market",
    loginToExcoinoMarket: "Log in",
    or: "or",
    createNewAccount: "Create a New Account",
    excoinoMarketChart: "Excoino Market Chart",
    globalChart: "Global Chart",
    sell: "Sell",
    buy: "Buy",
    price: "Price",
    amount: "Amount",
    transfer: "Transfer",
    balanceIncrease: "Increase Balance",
    openOrders: "Open Orders",
    stopLimit: "Stop/Limit",
    limitOrder: "Limit Order",
    marketOrder: "Market Order",
    limit: "Limit",
    ocoLimit: "OCO Limit",
    ocoStopLimit: "OCO Stop/Limit",
    turnover: "Volume",
    type: "Type",
    pairCoin: "Pair",
    direction: "Direction",
    activationPrice: "Activation Price",
    description: "Description",
    cancelOrder: "Cancel Order",
    cancelOrderConfirmation: "Are you sure you want to cancel the order?",
    confirm: "Confirm",
    cancel: "Cancel",
    ordersHistory: "Order History",
    orderDetails: "Order Details",
    refCode: "Reference Code",
    coin: "Cryptocurrency",
    symbol: "Pair",
    lastPrice: "Last Price",
    dayChange: "Daily Change",
    percent: "Traded Percentage",
    market: "Markets",
    favorite: "Favorite",
    do_favorite: "Add to Favorites",
    cancel_favorite: "Cancel Favorite",
    orderType: "Order Type",
    dealAmount: "Volume",
    vol: "24h Volume",
    limited_price: "Limit Order",
    market_price: "Market Order",
    stop_order_price: "Stop Order",
    fees_rate: "Fee Rate",
    balance: "Balances",
    canUse: "Available",
    recharge: "Deposit",
    buyPrice: "Buy Price",
    buyAmount: "Buy Amount",
    averageTradePrice: "Average Trade Price",
    buyTip: "Market Price",
    sellPrice: "Sell Price",
    stopPrice: "Activation Price",
    sellAmount: "Sell Amount",
    sellTip: "Market Price",
    stopLimitDelegation: "Stop/Limit",
    preconditionOrdersHistory: "Stop/Limit Orders History",
    num: "Amount",
    fee: "Fee",
    buySell: "Buy/Sell",
    time: "Time",
    stall: "Stopped",
    total: "Total",
    traded: "Traded Amount",
    action: "Action",
    undo: "Cancel",
    more: "Details",
    done: "Traded Amount",
    status: "Status",
    finished: "Completed",
    canceled: "Canceled",
    expired: "Expired",
    parted: "Parted",
    ordered: "Ordered",
    enterBuyAmount: "Please enter the buy amount",
    success: "Successful operation!",
    enterSellAmount: "Please enter the sell amount",
    successCancel: "Order cancellation successful",
    marketPrice: "Market Price",
    history: "History",
    preconditionOrders: "Stop/Limit Orders",
    orders: "Orders",
    demoMarket: "Demo Market",
    demoTrading: "Demo Trading",
    modalText01:
      "The demo market is an educational and testing market that allows you to trade with virtual currencies like V-USDT, V-BTC, and V-ETH. These currencies are only for testing and learning purposes and have no real value. You cannot convert them to Rial or Dollar or withdraw them from the system. You can only exchange them in the demo market with each other.",
    modalText02:
      "All users receive some V-USDT for free after registration. You can use this currency to place buy or sell orders for other currencies in the demo market. For example, you can convert your V-USDT to V-BTC. To do this, you need to enter the desired price and quantity and then confirm your order. The system will automatically match your order with suitable orders from other users and execute the trade.",
    modalText03:
      "You can use this market to familiarize yourself with the system's capabilities without risking your real assets and then proceed to real trading.",
    expand: {
      time: "Time",
      price: "Price",
      amount: "Amount",
      fee: "Fee",
      volume: "Trade Volume"
    },
    day: "Day",
    hours: "Hours",
    minutes: "Minutes",
    seconds: "Seconds",
    noOrders: "No orders available",
    realtime: "Real-time",
    kline: "Chart",
    depth: "Depth Chart",
    publishState0: "About to start. Please wait...",
    publishState1: "About to end. Please wait...",
    publishState2: "Merging orders, please wait...",
    publishState3: "Merging completed, feel free to trade...",
    publishCountTxt0: "Starts after",
    publishCountTxt1: "Ends after",
    publishCountTxt2: "Time remaining to start trading",
    publishCountTxt3: "Activity finished!",
    insufficientBalance: "Your wallet balance is insufficient",
    limitOrderPrice: "Limit Order Price",
    enterLimitOrderPrice: "Please enter the limit order price.",
    enterActivationPrice: "Please enter the activation price.",
    enterBuyPrice: "Please enter the buy price.",
    enterSellPrice: "Please enter the sell price.",
    ocoOrderInfoModal: {
      title: "How to use OCO feature",
      buyOrder: "Buy Order",
      sellOrder: "Sell Order",
      ocoTrades:
        "OCO trades: This means that you can place two orders simultaneously while trading, one as a stop order and the other as a limit order.",
      buyTrades: "Buy trades:",
      buyStop:
        "Stop mode: In this mode, the stop order is placed above the current market price.",
      buyLimit:
        "Limit mode: In this mode, the limit order is placed below the current market price.",
      desc1:
        "The stop price in a buy trade (point B) should be above the current market price for a buy order, and the limit price (point C) should be below the current market price.",
      notice: "Note:",
      desc2:
        "A stop order means that when the price reaches point B (3000), the order is activated, and simultaneously the limit order is canceled. Conversely, if the price falls to or below 1500 (point C), the limit order is activated, and the stop order is canceled.",
      desc3:
        "OCO trades: This means that you can place two orders simultaneously while trading, one as a stop order and the other as a limit order.",
      sellTrades: "Sell trades:",
      sellStop:
        "Stop mode: In this mode, the stop order is placed below the current market price.",
      sellLimit:
        "Limit mode: In this mode, the limit order is placed above the current market price.",
      desc4:
        "The stop price in a sell trade (point C) should be below the current market price for a sell order, and the limit price (point B) should be above the current market price.",
      desc5:
        "A stop order means that when the price reaches point C (1500), the order is activated, and simultaneously the limit order is canceled. Conversely, if the price rises to or above 3000 (point B), the limit order is activated, and the stop order is canceled.",
      understood: "Got it"
    },
    preconditionOrderInfoModal: {
      title: "How to use Stop/Limit feature",
      buyOrder: "Buy Order",
      sellOrder: "Sell Order",
      desc1:
        "The price of your desired currency is at point A, and if it reaches point C, your buy order will be executed, allowing you to receive more of the desired currency than at point A, resulting in a profit. Conversely, if the price reaches point B, your buy order will be executed, preventing you from buying at higher prices and incurring more loss.",
      desc2:
        "Important Note: The activation point is the precondition for executing the trade, and after the price reaches the activation point, the second condition, which is reaching the trade price (B or C), is necessary to execute the trade. Activation points (dashed lines) can be higher or lower than the trade price. Ensure that the activation point is selected carefully, as the price may reach the activation point but not the trade price, preventing the trade from occurring.",
      desc3: "* Separate orders must be created for each of the B or C orders.",
      desc4:
        "The purchase price of your currency is at point A, and if it reaches point B, your sell order will be profitable. Conversely, if the price reaches point C, your sell order will be executed, preventing further loss.",
      desc5:
        "Important Note: The activation point is the precondition for executing the trade, and after the price reaches the activation point, the second condition, which is reaching the trade price (B or C), is necessary to execute the trade. Activation points (dashed lines) can be higher or lower than the trade price. Ensure that the activation point is selected carefully, as the price may reach the activation point but not the trade price, preventing the trade from occurring.",
      desc6: "* Separate orders must be created for each of the B or C orders.",
      understood: "Got it"
    }
  };
  