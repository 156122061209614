export default {
    filter: "فیلتر",
    trxRangeTime: "بازه زمانی تراکنش",
    orderType: "نوع سفارش",
    direction: "جهت بازار",
    search: "جستجو",
    buy: "خرید",
    sell: "فروش",
    clearFilters: "پاک کردن فیلترها",
    stopLimit: "حد سود/ضرر",
    oco: "OCO",
    marketPrice: "قیمت بازار",
    successCancel: "لغو سفارش موفقیت آمیز است",
    time: "زمان",
    ocoStopLimit: "حد سود/ضرر (OCO)",
    pairCoin: "جفت ارز",
    price: "قیمت",
    amount: "مقدار",
    tradeDirection: "جهت معامله",
    activationPrice: "قیمت فعال‌سازی",
    cancelOrder: "لغو سفارش",
    cancelOrderDescription: "آیا از لغو سفارش خود مطمئن هستید؟",
    confirm: "تایید",
    cancel: "انصراف",
    refCode: "کد پیگیری",
    operation: "عملیات",
    undo: "لغو",
}