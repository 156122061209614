import Vue from "vue";
import VueRouter from "vue-router";
import ViewUI from "view-design";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    meta: { authenticated: false, transitionName: "slide" },
    component: (resolve) =>
      require(["../pages/App/Layout/AppLayout/index"], resolve),
    children: [
      {
        path: "/markets",
        name: "Markets",
        meta: { authenticated: false, transitionName: "slide" },
        component: (resolve) => require(["../pages/Markets/index"], resolve),
      },
      {
        path: "/profile",
        name: "Profile",
        meta: { authenticated: true, transitionName: "slide" },
        component: (resolve) => require(["../pages/Profile/index"], resolve),
      },
      {
        path: "/assets",
        name: "Assets",
        meta: { authenticated: true, transitionName: "slide" },
        component: (resolve) => require(["../pages/Assets/index"], resolve),
      },
      {
        path: "/transactions",
        name: "Transactions",
        meta: { authenticated: true, transitionName: "slide" },
        component: (resolve) =>
          require(["../pages/Transactions/index"], resolve),
      },
      // {
      //   path: "/deposit",
      //   name: "Deposit",
      //   meta: { authenticated: true, transitionName: "slide" },
      //   component: (resolve) => require(["../pages/Deposit/index"], resolve),
      // },
      {
        path: "/transfer",
        name: "Transfer",
        meta: { authenticated: true, transitionName: "slide" },
        component: (resolve) => require(["../pages/Withdraw/index"], resolve),
      },
      // {
      //   path: "/withdraw-addresses",
      //   name: "Withdraw Addresses",
      //   meta: { authenticated: true, transitionName: "slide" },
      //   component: (resolve) =>
      //     require(["../pages/WithdrawAddress/index"], resolve),
      // },
      {
        path: "/open-orders",
        name: "OpenOrders",
        meta: { authenticated: true, transitionName: "slide" },
        component: (resolve) => require(["../pages/OpenOrders/index"], resolve),
      },
      {
        path: "/orders-history",
        name: "OrdersHistory",
        meta: { authenticated: true, transitionName: "slide" },
        component: (resolve) =>
          require(["../pages/OrdersHistory/index"], resolve),
      },
      {
        path: "/precondition-orders-history",
        name: "PreconditionOrdersHistory",
        meta: { authenticated: true, transitionName: "slide" },
        component: (resolve) =>
          require(["../pages/PreconditionOrdersHistory/index"], resolve),
      },
      {
        path: "/stop-limit-orders",
        name: "StopLimitOrders",
        meta: { authenticated: true, transitionName: "slide" },
        component: (resolve) =>
          require(["../pages/StopLimitOrders/index"], resolve),
      },
      {
        path: "/guide",
        name: "Guide",
        meta: { authenticated: false, transitionName: "slide" },
        component: (resolve) => require(["../pages/Guide/index"], resolve),
      },
      {
        path: "/terms-of-use",
        name: "TermsOfUse",
        meta: { authenticated: false, transitionName: "slide" },
        component: (resolve) => require(["../pages/TermsOfUse/index"], resolve),
      },
    ],
  },
  {
    path: "/exchange",
    name: "Exchange",
    meta: { authenticated: false, transitionName: "slide" },
    component: (resolve) => require(["../pages/Exchange/index"], resolve),
    children: [
      {
        path: ":pair",
        meta: { authenticated: false, transitionName: "slide" },
        props: true,
        component: (resolve) => require(["../pages/Exchange/index"], resolve),
        name: "ExchangePair",
      },
    ],
  },
  {
    path: "/staking",
    name: "Staking",
    meta: { authenticated: false, transitionName: "slide" },
    component: (resolve) => require(["../pages/Staking/index"], resolve)
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { authenticated: true, transitionName: "slide" },
    component: (resolve) => require(["../pages/Dashboard/index"], resolve),
  },
  {
    path: "/affiliate",
    name: "Affiliate",
    meta: { authenticated: true, transitionName: "slide" },
    component: (resolve) => require(["../pages/Affiliate/index"], resolve),
  },
  {
    path: "*",
    redirect: "/markets",
  },
  // {
  //   path : "/exchange",
  //   component: resolve => require( ["../pages/App/Layout/ExchangeLayout/index"], resolve ),
  //   children : [
  //       {
  //         path: "",
  //         meta: { transitionName: "slide" },
  //         component: resolve => require( ["../pages/Exchange/index"], resolve),
  //       },
  //       {
  //         path: ":pair",
  //         meta: { transitionName: "slide" },
  //         component: resolve => require( ["../pages/Exchange/index"], resolve),
  //         name: "ExchangePair"
  //       },
  //   ]
  // }
  // =========================
  //  FOR 404 PAGE
  // =========================
  // {
  //   path: "*",
  //   component: resolve => require( ["../pages/App/Layout/AppLayout/index"], resolve)
  // },
];

const router = new VueRouter({
  mode: "history",
  base:"/market",
  routes,
});

ViewUI.LoadingBar.config({
  color: "#F90",
  failedColor: "#f0ad4e",
  height: 2,
});

router.beforeEach(async (to, from, next) => {
  ViewUI.LoadingBar.start();
  if (to.matched[0].path !== from.path) {
    const initUser = store.dispatch("userCenter/INIT_APP");
    initUser
      .then((response) => {
        if (response.data.code === 0) {
          if (to.name === undefined) {
            next({name: "Dashboard"});
          }
          // (to.name === "Deposit" && !response.data.data.depositStatus)
          if (to.name === "Transfer" && !response.data.data.withdrawStatus) {
            next({ name: "Assets" });
          } else {
            next();
          }
        }
        // switch(response.data.code) {
        //   case 0:
        //     if ((to.name === "Withdraw" && !response.data.data.withdrawStatus) || (to.name === "Deposit" && !response.data.data.depositStatus)) {
        //       next({name: "Assets"});
        //     } else {
        //       next();
        //     }
        //     return;
        // case 401:
        //   if (to.meta.authenticated) {
        //      window.location = process.env.VUE_APP_EXCOINO_PANEL_URL;
        //   }
        //   store.dispatch("global/SET_MEMBER", null);
        //   next();
        //   return;
        // case 403:
        //     if ((to.name === "Withdraw") || (to.name === "Deposit")) {
        //       next({name: "Assets"});
        //     } else {
        //       Message.info("کاربر گرامی دسترسی شما غیر فعال است، لطفا با پشتیبانی تماس حاصل فرمایید.");
        //       next();
        //     }
        // default:
        //   next();
        // }
      })
      .catch((err) => {
        if (err.code === 401) {
          if (to.name === undefined) {
            next("/markets")
          }
          if (to.meta.authenticated) {
            window.location = process.env.VUE_APP_EXCOINO_PANEL_URL;
          }
          store.dispatch("global/SET_MEMBER", null);
          next();
        } else if (err.code === 403) {
          // if (to.name === "Withdraw" || to.name === "Deposit") {
          if (to.name === "Transfer") {
            next({ name: "Assets" });
          } else {
            // Message.info(
            //   "کاربر گرامی دسترسی شما غیر فعال است، لطفا با پشتیبانی تماس حاصل فرمایید."
            // );
            next();
          }
        }
        next();
      });
  } else {
    next();
  }
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  ViewUI.LoadingBar.finish();
});

export default router;
