export default {
    openOrders: "سفارشات باز",
    filter: "فیلتر",
    trxRangeTime: "بازه زمانی تراکنش",
    symbol: "رمزارز",
    orderType: "نوع سفارش",
    direction: "جهت بازار",
    search: "جستجو",
    buy: "خرید",
    sell: "فروش",
    clearFilters: "پاک کردن فیلترها",
    limit: "محدود",
    market: "آنی",
    stopLimit: "حد سود/ضرر",
    oco: "OCO",
    successCancel: "لغو سفارش موفقیت آمیز است",
    orderDetails: "جزییات سفارش",
    time: "زمان",
    type: "نوع",
    ocoLimit: "محدود (OCO)",
    ocoStopLimit: "حد سود/ضرر (OCO)",
    pairCoin: "جفت ارز",
    price: "قیمت",
    amount: "مقدار",
    turnOver: "حجم معاملات",
    tradeDirection: "جهت معامله",
    activationPrice: "قیمت فعال‌سازی",
    description: "توضیحات",
    cancelOrder: "لغو سفارش",
    cancelOrderDescription: "آیا از لغو سفارش خود مطمئن هستید؟",
    confirm: "تایید",
    cancel: "لغو",
    limitOrder: "سفارش محدود",
    marketOrder: "سفارش آنی",
    tradedAmount: "مقدار معامله‌شده",
    tradedPercent: "درصد معامله شده",
    averageTradePrice: "میانگین قیمت معامله‌شده",
    refCode: "کد پیگیری",
    operation: "عملیات",
    undo: "لغو",
    details: "جزییات"
}