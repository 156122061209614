export default {
  openOrders: "الأوامر المفتوحة",
  filter: "تصفية",
  trxRangeTime: "نطاق وقت المعاملة",
  symbol: "العملة المشفرة",
  orderType: "نوع الأمر",
  direction: "اتجاه السوق",
  search: "بحث",
  buy: "شراء",
  sell: "بيع",
  clearFilters: "مسح الفلاتر",
  limit: "حد",
  market: "سوق",
  stopLimit: "حد/إيقاف",
  oco: "OCO",
  successCancel: "تم إلغاء الأمر بنجاح",
  orderDetails: "تفاصيل الأمر",
  time: "الوقت",
  type: "نوع",
  ocoLimit: "حد OCO",
  ocoStopLimit: "حد/إيقاف OCO",
  pairCoin: "زوج العملات",
  price: "السعر",
  amount: "الكمية",
  turnOver: "حجم التداول",
  tradeDirection: "اتجاه التجارة",
  activationPrice: "سعر التفعيل",
  description: "الوصف",
  cancelOrder: "إلغاء الأمر",
  cancelOrderDescription: "هل أنت متأكد أنك تريد إلغاء الأمر؟",
  confirm: "تأكيد",
  cancel: "إلغاء",
  limitOrder: "أمر حد",
  marketOrder: "أمر سوق",
  tradedAmount: "الكمية المتداولة",
  tradedPercent: "نسبة التداول",
  averageTradePrice: "متوسط سعر التداول",
  refCode: "رمز المرجع",
  operation: "عملية",
  undo: "تراجع",
  details: "تفاصيل"
};
