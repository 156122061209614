export default {
  common: {
    area: "/uc/support/country",
  },
  uc: {
    //member center interface
    login: "/uc/login",
    register: "/uc/register",
    wallet: "/uc/asset/wallet/",
    captcha: "/uc/start/captcha",
    identification: "/uc/approve/certified/business/status", //merchant certification
    apply: "/uc/approve/certified/business/apply", //merchant certification application
    announcement: "/uc/announcement/page", //announcement list
    paydividends: "/uc/bonus/user/page", //holding currency dividends
    mylistrecord: "/uc/mine/detail/", //transaction mining details
    activitylist: "/uc/activity/page-query", // events list
    memberactivity: "/uc/activity/getmemberrecords", // user participation activity list
    attendActivity: "/uc/activity/attend", // Participate
    mypromotion: "/uc/promotion/mypromotion", // my promotion commission
    toppromotion: "/uc/promotion/toprank", // promotion partner ranking
    getfreecard: "/uc/promotion/promotioncard/getfreecard", // get a free promotion card
    exchangecard: "/uc/promotion/promotioncard/exchangecard", // redeem promotion card
    mycardlist: "/uc/promotion/promotioncard/mycard", // redeem promotion card
    toppromotionmonth: "/uc/promotion/monthtoprank", // promotion partner ranking
    toppromotionweek: "/uc/promotion/weektoprank", // promotion partner ranking
    memberInfo: "/uc/member/my-info", // get user information
    mypromotionrecord: "/uc/promotion/record", //get the record of the promotion inviter
    myInnovationOrderList: "/uc/activity/getmyorders", // List of Participating Orders in the Innovation Experimental Zone
    myInnovationMinings: "/uc/miningorder/my-minings", // get a list of my miners
    memberMaxWithdraw: "/uc/withdraw/find-member-max-withdraw-amount",
  },
  market: {
    //currency trading quotation interface
    ws: "/market/market-ws",
    thumb: "/market/symbol-thumb",
    thumbTrend: "/market/symbol-thumb-trend",
    plate: "/market/exchange-plate", //actively queried handicap information
    platemini: "/market/exchange-plate-mini", //get 10 data
    platefull: "/market/exchange-plate-full", //get all data
    trade: "/market/latest-trade", //actively query real time transaction information
    symbolInfo: "/market/symbol-info",
    coinInfo: "/market/coin-info",
    indexData: "/market/index_info",
  },
  exchange: {
    //Currency transaction commission submission and query interface
    orderAdd: "/exchange/order/add", //submit order interface
    current: "/exchange/order/current", //current delegate interface
    history: "/exchange/order/history", //historical delegation interface
    detail: "/exchange/order/detail/v2", //detailed order interface
    favorFind: "/exchange/favor/find", //query optional
    favorAdd: "/exchange/favor/add", //add optional
    favorDelete: "/exchange/favor/delete", //delete optional
    orderCancel: "/exchange/order/cancel", //cancel order
    exchangeOrderDetail: "/exchange/order/detail/v2", // get the partial order details
  },
  otc: {
    coin: "/otc/coin/all", //query supported currencies
    advertise: "/otc/advertise/page-by-unit", //get ads
    createAd: "/uc/ad/create", //create ad
    adDetail: "/otc/advertise/detail",
  },
  activity: {
    activity: "/activity/page-query",
  },
};
