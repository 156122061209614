export default {
  SET_TERMS_OF_USE_MODAL_STATUS: ({ commit }, showModal) => {
    commit("SET_TERMS_OF_USE_MODAL_STATUS", showModal);
  },
  SET_DEPOSIT_STATUS: ({ commit }, nav) => {
    commit("SET_DEPOSIT_STATUS", nav);
  },
  SET_WITHDRAW_STATUS: ({ commit }, nav) => {
    commit("SET_WITHDRAW_STATUS", nav);
  },
  SET_TRANSFER_STATUS: ({ commit }, nav) => {
    commit("SET_TRANSFER_STATUS", nav);
  },
  SET_STATUS_CODE: ({ commit }, payload) => {
    commit("SET_STATUS_CODE", payload);
  },
  SET_TRADING_STATUS: ({ commit }, payload) => {
    commit("SET_TRADING_STATUS", payload);
  },
  NAVIGATE: ({ commit }, nav) => {
    commit("NAVIGATE", nav);
  },
  SET_MEMBER: ({ commit }, member) => {
    commit("SET_MEMBER", member);
  },
  RECOVERY_MEMBER: ({ commit }) => {
    commit("RECOVER_MEMBER");
  },
  SET_LANG: ({ commit }, lang) => {
    commit("SET_LANG", lang);
  },
  INIT_LANG: ({ commit }) => {
    commit("INIT_LANG");
  },
  INIT_LOGIN_TIMES: ({ commit }, times) => {
    commit("INIT_LOGIN_TIMES", times);
  },
  SET_LOGIN_TIMES: ({ commit }, times) => {
    commit("SET_LOGIN_TIMES", times);
  },
  SET_SKIN: ({ commit }, skin) => {
    commit("SET_SKIN", skin);
  },
};
