export default {
  confirmTermsOfUse: "می‌پذیرم",
  denyTermsOfUse: "نمی‌پذیرم",
  title: "شرایط و مقررات استفاده از خدمات",
  subTitle: "شرکت توسعه فناوری آرین داده پیوسته ایرانیان",
  terms: [
    {
      id: "1",
      title: "1. مقدمه",
      desc: [
        {
          id: "1",
          text: "شرکت توسعه فن آوری آرین داده پیوسته ایرانیان (سهامی خاص) به شناسه ملی 14008195510 در این سند به بیان ضوابط و مقررات استفاده از خدمات اکسکوینو مارکت می پردازد. این محصول با هدف ارائه خدمات در حوزه رمز ارز به کاربران طراحی شده و برابر با شرایط و ضوابط آتی نسبت به ارائه خدمات به کاربران اقدام می‌نماید. شرایط حاضر به‌عنوان یک قرارداد قطعی و لازم الاجرا با رعایت ماده 10 و 219 قانون مدنی بر روابط بین کاربران و شرکت حاکم خواهد بود. ورود کاربران به اکسکوینو مارکت به منزله پذیرش و قبول تمامی شرایط و ضوابط این سند است. لذا ضروری‌ست پیش از استفاده از خدمات شرکت، این شرایط و ضوابط را به دقت مطالعه فرمایید.",
        },
      ],
    },
    {
      id: "2",
      title: "2. تعاریف و اصطلاحات",
      desc: [
        {
          id: "1",
          text: "1- شرکت: منظور شرکت توسعه فن آوری آرین داده پیوسته ایرانیان (سهامی خاص) به شناسه ملی 14008195510 است که مالک محصول اکسکوینو مارکت است.",
        },
        {
          id: "2",
          text: "2- اکسکوینو مارکت: فضای اختصاصی به آدرس <a href='https://market.excoino.com' target='_blank'>market.excoino.com</a> که برای خرید و فروش رمز ارزها از طریق پنل معاملاتی سایت اکسکوینو قابل دسترس برای کاربران است.",
        },
        {
          id: "3",
          text: "3- کیف پول رمز ارز: کیف پول رمزارز محل ذخیره سازی، واریز و برداشت ارز در شبکه زنجیره بلاکچین است.",
        },
        {
          id: "4",
          text: "4- کاربر: شخص حقیقی است که با آزادی اراده نسبت به ورود، بازدید و استفاده از اکسکوینو مارکت اقدام و با رعایت کامل قوانین و مقررات این سند، خدمات مربوطه را از شرکت دریافت می نماید.",
        },
        {
          id: "5",
          text: "5- حساب کاربری: حساب کاربری است که توسط اشخاص حقیقی با مسئولیت کامل و تام شخص در خصوص اظهار اطلاعات صحیح، کافی،کامل، شفاف و با رعایت مقررات و قوانین جمهوری اسلامی ایران و شرایط مقرر شده از سوی شرکت در اکسکوینو ساخته می شود.",
        },
        {
          id: "6",
          text: "6- رمز ارز: کریپتوکارنسی یا رمزارز به دسته‌ای از پول و ارزهای دیجیتال گفته می‌شود که در آن‌ها از رمزنگاری (کریپتوگرافی و کد گذاری شده) برای تراکنش‌های مالی استفاده شده ‌است.",
        },
        {
          id: "7",
          text: "7- رمزارز پذیرفته شده: منظور رمز ارزی است که توسط اکسکوینو مارکت لیست و اعلام شده و مورد پذیرش شرکت برای خرید و فروش است. ",
        },
        {
          id: "8",
          text: "8- فورس ماژور: منظور از فورس ماژور، وقوع هرگونه حادثه خارجی، غیر قابل دفع، غیر قابل کنترل و غیر قابل پیش‌بینی است که مستند به فعل یا ترک فعل هیچ‌یک از طرفین (شرکت و کاربر) نباشد. از جمله مصادیق آن می‌توان به سیل، زلزله، آتش‌سوزی، جنگ، اعتصاب، بیماری‌های واگیردار، قطعی سراسری اینترنت، قطع سراسری برق و یا دیگر وقایع مرتبط و هم سنخ است.",
        },
      ],
    },
    {
      id: "3",
      title: "3. کلیات",
      desc: [
        {
          id: "1",
          text: "1- کاربر با ورود به پنل اکسکوینو مارکت می‌پذیرد که شرایط و قوانین این سند را به صورت کامل و دقیق مطالعه نموده، با اراده آزاد آن را قبول و پذیرفته است. لذا در آینده هر گونه اعتراض یا عدم اطلاع و ادعای جهل و یا هرگونه ایرادی از سوی کاربر در این خصوص فاقد اعتبار است.",
        },
        {
          id: "2",
          text: "تبصره: هرگونه علامت، عدد، کلمه، تصویر یا نشان الکترونیک که مثبِت هویت امضا کننده باشد و به این سند یا سایر اسناد صادره در این زمینه ملحق شود به‌منزله امضای الکترونیک و همانند امضای دست‌نویس واجد اعتبار و سندیت خواهد بود و امضاکننده را به مفاد سند ملتزم می‌‌نماید و اثبات خلاف آن نیز قابل استماع نمی باشد.",
        },
        {
          id: "3",
          text: "2- شرکت مجاز و محق است که در مفاد این سند در هر زمان تغییراتی را به صورت کلی و یا جزئی اعمال نماید. بدیهی ا‌ست این تغییرات به اطلاع کاربر از طریق مقتضی خواهد رسید. در صورتی که پس از اطلاع رسانی، کاربر به استفاده خود از خدمات اکسکوینو مارکت ادامه دهند، این امر به منزله پذیرش کلیه تغییرات از سوی کاربران است.",
        },
        {
          id: "4",
          text: "3- شرکت، خدمات ذیل را به کاربران در اکسکوینو مارکت ارائه می دهد: ",
          detail: [
            {
              id: "5",
              text: "خرید و فروش رمزارزهای پذیرفته شده میان کاربران،",
            },
            {
              id: "6",
              text: "واریز و برداشت رمزارزهای پذیرفته شده،",
            },
            {
              id: "7",
              text: "انتقال رمزارزهای پذیرفته شده به اکسکوینو و بالعکس.",
            },
          ],
        },
        {
          id: "8",
          text: "4- کارمزدهای دریافتی در فاکتور ارتباطی با کارمزدهای شبکه ندارند و به طور مجزا محاسبه و اخذ می گردد.",
        },
        {
          id: "9",
          text: "5- نرخ خرید و فروش رمز ارزها در اکسکوینو مارکت توسط کاربران مشخص می شود. نرخ کارمزد خدمات اکسکوینومارکت، در هر سفارش با نرخ مشخص نشان داده می شود و به محض انعقاد معامله از طرفین دریافت می گردد.",
        },
        {
          id: "10",
          text: "6- هر گونه کارمزد، کمیسیون و هزینه دیگر که سیستم بلاکچین و یا رمز ارزها به کاربر تحمیل نماید، فقط بر عهده کاربر خواهد بود و کاربر موظف به بررسی این موارد قبل از سفارش می باشد و موارد مذکور ارتباطی به شرکت نداشته و شرکت هیچ گونه مسئولیتی راجع به این گونه هزینه ها بر عهده ندارد.",
        },
        {
          id: "11",
          text: "7- انتخاب رمزارزها برای لیست شدن و پذیرفته شدن در اکسکوینو مارکت، حسب صلاحدید و تصمیم شرکت صورت می‌پذیرد. شرکت مجاز و محق می باشد که در هر زمانی لیست رمز ارزهای پذیرفته شده را کاهش یا افزایش دهد.",
        },
        {
          id: "12",
          text: "8- اکسکوینو مارکت هیچگونه ارتباطی با سایت های قمار و شرط بندی ندارد و در صورت استفاده کاربران از خدمات اکسکوینو مارکت برای این گونه سایت ها، حساب کاربری ایشان بلافاصله مسدود و پیگیری قانونی از سوی شرکت صورت خواهد گرفت.",
        },
        {
          id: "13",
          text: "9- در صورتی که بر اثر فعالیت های غیر قانونی یا خرابکارانه کاربران به شرکت، محصولات آن و یا کاربران آن زیانی وارد شود، شرکت می تواند از مجاری قانونی نسبت به تعقیب جزایی و حقوقی مربوطه در راستای اعمال مجازات و اخذ کلیه خسارت متصور اقدام می نماید.",
        },
        {
          id: "14",
          text: "10- کلیه دارایی فکری متصور در خصوص اکسکوینو مارکت انحصاراً و بدون هیچ‌گونه قید و شرطی در مالکیت شرکت بوده و حق انحصاری بهره‌برداری از آن متعلق به شرکت است. هر گونه نقض این مالکیت موجب پیگرد حقوقی و کیفری خواهد بود.",
        },
        {
          id: "15",
          text: "11- هرگونه اخطار و اطلاعیه از طریق اکسکوینو مارکت تنها به ایمیل و یا شماره تماس اعلامی کاربران هنگام ثبت‌‌نام در اکسکوینو و یا از طریق حساب کاربری ارسال و به کاربران اطلاع رسانی خواهد شد. همچنین کاربران می‌توانند با آدرس مندرج در قسمت تماس با ما، جهت اخذ اطلاعات ضروری و یا ارائه پیشنهادات و هرگونه سوال در ارتباط باشند.",
        },
        {
          id: "16",
          text: "12- در صورتی که ارائه خدمات شرکت خللی ایجاد شود که مستند به فورس ماژور باشد، شرکت در این خصوص مسئولیتی بر عهده ندارد.",
        },
        {
          id: "17",
          text: "13- در صورت شناسایی و تشخیص رفتار غیر متعارف و یا غیر واقعی و یا غیر قانونی کاربر در رابطه با فعالیت در اکسکوینو مارکت، شرکت ضمن اخطار به کاربر، نسبت به مسدود سازی حساب وی اقدام می نماید. رفع مسدودی حساب کاربری، تنها با احراز مجدد هویتی و فعالیت صحیح کاربر بنا به تشخیص شرکت انجام می گردد. بدیهی در صورتی که رفتار مذکور غیر قانونی و یا خلاف قوانین و مقررات باشد، برابر با قوانین و یا ضوابط مربوطه با کاربر برخورد صورت خواهد گرفت و مراتب به مراجع ذیصلاح اعلام می گردد.",
        },
        {
          id: "18",
          text: "14- هر گونه استفاده از نام و علامت اکسکوینو مارکت بدون کسب اجاره کتبی قبلی از شرکت پیگیرد قانونی دارد.",
        },
        {
          id: "19",
          text: "15- امکان واریز و برداشت ارزها و انتقال آن ها از اکسکوینو به اکسکوینومارکت و بالعکس وجود دارد.",
        },
        {
          id: "20",
          text: "16- کلیه پیامک های اطلاع رسانی اکسکوینو مارکت، از طریق سرشماره اختصاصی اکسکوینو به شماره 10000007000777  و آدرس ایمیل no-reply@excoino.com ارسال خواهد شد و شرکت مسولیتی در قبال پیامک های ارسال شده، از شماره هایی غیر از شماره و یا ایمیل مذکور، نخواهد داشت.",
        },
        {
          id: "21",
          text: "17- برای استفاده از خدمات اکسکوینو مارکت، الزاما نیاز به ثبت نام و ایجاد حساب کاربری در اکسکوینو دارید.",
        },
        {
          id: "22",
          text: "18- شرکت مجاز است برای اطمینان از صحت تراکنش ها در هر زمان، از کاربران اطلاعات بیشتری برای احراز هویت آنان و یا تراکنش های کاربر را درخواست نماید و کاربر موظف به ارائه اطلاعات مورد نیاز بوده؛ در غیر این صورت شرکت مجاز به مسدود نمودن حساب کاربری می باشد.",
        },
        {
          id: "23",
          text: "19- کاربر با ورود به پنل اکسکوینو مارکت می پذیرد که در استفاده از خدمات، از تمامی قوانین جمهوری اسلامی ایران از جمله قانون مبارزه با پولشویی تبعیت نماید. در غیر این صورت، پاسخ گویی و مسئولیت اعم کیفری و حقوقی صرفاً با کاربر است.",
        },
        {
          id: "24",
          text: "20 - حساب کاربری قائم به شخص بوده و قرار دادن حساب کاربری خود در اختیار اشخاص دیگر در هر قالبی (حساب بانکی اجاره ای، اطلاعات کاربری و...) ممنوع می باشد. در صورت احراز این موارد حساب کاربری فرد خاطی مسدود شده و هرگونه عواقب و مسئولیت های ناشی این امر بر عهده کاربر می باشد.",
        },
      ],
    },
    {
      id: "4",
      title: "4. تکالیف کاربران",
      desc: [
        {
          id: "1",
          text: "1- کاربر می پذیرد که منشاء و مقصد کلیه رمزارزها ارائه شده توسط وی در تراکنش های سایت اکسکوینو مارکت کاملاً  مطابق با ضوابط قانونی و مطابق با قوانین بین المللی و مقررات جمهوری اسلامی ایران است.",
        },
        {
          id: "2",
          text: "2- کاربر موظف است قبل از معامله و هرگونه تراکنش، عدد کارمزد را با دقت مطالعه کند و با آگاهی کامل معامله را تایید نماید. در صورت اعتراض کاربر به کسر کارمزد یا ناآگاهی از میزان آن، این دست از ادعاها/شکایات مورد پذیرش شرکت نبوده و شرکت هیچ مسئولیتی در این باره ندارد.",
        },
        {
          id: "3",
          text: "3- کاربر موظف است سفارش خود را با دقت کامل ثبت نماید، در صورت ادعای هرگونه اشتباه، امکان لغو، برگشت ارز امکان پذیر نبوده و شرکت در خصوص این گونه از اشتباهات کاربران مسئولیتی بر عهده ندارد و با تکلیفی مواجه نمی باشد.",
        },
        {
          id: "4",
          text: "4- با توجه به اقتضائات شبکه بلاکچین، هر آدرس کیف پول رمز ارز، فقط متعلق به آن رمز ارز است و به علت شباهت آدرس بعضی رمز ارزها به یگدیگر، در صورت واریز اشتباه رمز ارز دیگری به آدرس مشخص شده، تمام مسئولیت واریز اشتباه بر عهده کاربر می باشد و شرکت هیچ مسئولیتی در قبال اشتباه واریز کاربر بر عهده ندارد.",
        },
        {
          id: "5",
          text: "5- کاربر تایید و اقرار می نماید که از ریسک‌های مربوط به سرمایه‌گذاری در حوزه ارزهای دیجیتال مطلع بوده و با علم به این موضوع اقدام به سرمایه‌گذاری و تبادل رمز ارز می‌نمایند.",
        },
        {
          id: "6",
          text: "6- کاربر موظف است در هنگام واریز (شارژ) هر ارزی به کیف پول خود، به ادرس کیف پول ارائه شده و یا هر موردی که مربوط به ارسال رمز ارز به کیف پول می باشد دقت نماید. در صورت عدم توجه و واریز یا شارژ به آدرس اشتباه و مغایر با کیف پول و یا موارد مربوطه و یا شبکه متفاوت از آنچه در اکسکوینو مارکت ارائه می شود (شبکه BSC)، شرکت هیچ مسئولیتی در قبال اشتباه کاربر ندارد و کاربر حق هیچ ادعایی و اعتراضی در قبال اشتباه خود ندارد و حقوق خود را در این اسقاط می نماید.",
        },
        {
          id: "7",
          text: "7- به منظور امنیت و صحت تراکنش ها، استفاده از هرگونه ابزار غیر مجاز، ربات و فعالیت (ترید یا معامله) مشکوک به هر نحوی که برای اکسکوینو مارکت محرز شود، ممنوع می باشد. در صورت استفاده کاربر از این موارد و تشخیص شرکت، تمامی تراکنش های مربوط به این عملیات، غیر قابل قبول و لغو خواهند شد و فاقد هر گونه اثر مالی و حقوقی هستند و فقط اصل دارایی ورودی کاربر به ایشان واریز و حساب ایشان غیر فعال می شود.",
        },
        {
          id: "8",
          text: "8- کاربر می پذیرد که کلیه فعالیت های وی و شرکت تحت قوانین جمهوری اسلامی ایران است و فعالیت های او در هر صورت و هر شکل نباید قوانین جمهوری اسلامی ایران را نقض کند، در صورت نقض قوانین، سوء استفاده مالی و غیر مالی و هرگونه فعالیت مشکوک به هر روشی، حساب کاربری فرد جهت بررسی مربوطه مسدود (حداقل 48 الی 72 ساعت کاری) و در صورت مشاهده تخلف، اطلاعات هویتی و مالی کاربر به منظور پیگیری در اختیار مراجع قانونی ذیصلاح قرار می گیرد وکاربر بدون قید، شرط و هرگونه ادعایی ملزم به جبران کلیه خسارت متصوره خواهد بود.",
        },
      ],
    },
    {
      id: "5",
      title: "5. شرایط معامله",
      desc: [
        {
          id: "1",
          text: "1- در صورت بروز هرگونه جهاتی مبنی بر مبهم یا مشکوک بودن در معاملات و یا نقض قوانین و یا ضوابط و مقررات مقرر در معاملات کاربران، شرکت مجاز و محق است آن معامله را بدون مراجعه به مراجع قانونی باطل کرده و دارایی هر یک از طرفین معامله را به حساب خود بازگرداند. در این خصوص کاربران حق هرگونه ادعا، اعتراض و یا حق مراجعه به مراجع ذیربط را از خود سلب و ساقط نمودند.",
        },
        {
          id: "2",
          text: "2- به منظور جلوگیری از هرگونه تخلف و نقض ضوابط در زمینه مالی، مسئولیت انجام هر یک از تراکنش ها، بر عهده شخص کاربر می باشد. فرض بر این است که کاربر از امنیت حساب خود اطمینان دارد و اطلاعات حساب کاربری خود را در اختیار دیگران قرار نمی دهد.",
        },
        {
          id: "3",
          text: "3- اکسکوینو مارکت در مقابل واریز/برداشت توکن یا رمزارز بر بستر اشتباه یا رمزارزهایی که در شبکه اکسکوینو مارکت پشتیبانی نمی شود هیچ گونه مسئولیتی نداشته، لذا مسئولیت هرگونه واریز/برداشت اشتباه با شخص کاربر بوده و کاربر حق هیچ گونه شکایتی را در این زمینه نخواهد داشت.",
        },
        {
          id: "4",
          text: "4- سفارش هایی که از طرف اکسکوینو مارکت انجام شده و شماره تراکنش یا پیگیری آن ثبت شده است، به منزله تکمیل سفارش از سوی اکسکوینو مارکت است.",
        },
        {
          id: "5",
          text: "5- اگر اکسکوینو مارکت رمزارزی را به اشتباه به حساب کاربر منظور یا در محاسبات خود هر نوع اشتباهی نماید، شرکت در هر زمانی مجاز و مختار است بدون انجام هیچ گونه تشریفات اداری و قضائی نسبت به اصلاح اشتباه خود اقدام نماید. کاربر با تایید این سند اجازه و اذن خود را در این زمینه به شرکت اعلام می دارد و کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد شرکت از هر جهت از خود ساقط می نماید.",
        },
        {
          id: "6",
          text: "6- کارمزد دریافتی از سوی شرکت نهایی، قطعی و غیر قابل برگشت و استرداد بوده و در هیچ صورتی به کاربر عودت داده نمی شود.",
        },
        {
          id: "7",
          text: "7- اگر شرکت تحت هر عنوان سهواً و یا من غیر حق، رمزارزی را به حساب کاربر منظور یا در محاسبات خود یا نرخ ارائه شده هر نوع اشتباهی نماید، کاربر این اجازه را با قبول شرایط و قوانین به شرکت می دهد که در هر زمان مجاز و مختار است راساً و مستقلاً و بدون انجام هیچ گونه تشریفات اداری و یا قضائی و بدون نیاز به دریافت اجازه مجدد از کاربر، در راستای رفع اشتباه و برداشت از حساب‌های وی اقدام نماید و تشخیص شرکت نسبت به وقوع اشتباه یا پرداخت بدون حق و لزوم برداشت از حساب معتبر خواهد بود و کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد شرکت از هر جهت از خود ساقط می نماید.",
        },
        {
          id: "8",
          text: "8- در صورت هر گونه واریز/برداشت/معامله مشکوک، 48 تا 72 ساعت کاری تراکنش تحت بررسی قرار دهد.",
        },
        {
          id: "9",
          text: "9- به صورت معمول واریز ارزی به صورت آنی انجام می شود. فرآیند برداشت رمز ارز یا انتقال آن به اکسکوینو به طور معمول در ساعات کاری (9-17) حداکثر 45 دقیقه بعد از ثبت درخواست و در ساعات غیر کاری (17-24) و تعطیلات رسمی حداکثر تا 2 ساعت بعد از ثبت درخواست از سمت کاربر، انجام می شود. این زمان بندی، صرفاً در شرابط معمول می باشد و در شرایط فورس ماژور و یا در مواقع افزایش حجم سفارشات، نوسانات بازار و ترافیک شبکه ممکن است تراکنش ها با تاخیر انجام گردد و کاربر مجاز به پیگیری واریز صحیح تراکنش می باشد و نمی تواند هیچگونه ادعا اعم از ضرر و خسارت در این فرض نماید.",
        },
        {
          id: "10",
          text: "10- ممکن است در یک معامله هویت گیرنده با فرستنده، یکی باشد. اکسکوینومارکت یکسان بودن هویت گیرنده و فرستنده را بررسی نمی کند. اکسکوینومارکت هیچ گونه مسئولیتی راجع به رابطه بین خریدار با فروشنده نمی پذیرد و هیچ گونه خدمات میانجیگری و حکمیت بین این دو ندارد و در خصوص موارد ذکر شده با تکلیفی مواجه نمی باشد.",
        },
        {
          id: "11",
          text: "11- اکسکوینومارکت به هیچ عنوان مسئولیتی در قبال بازخرید توکن های مزبور نداشته و قیمت های مندرج در بازارهای اکسکوینومارکت همگی توسط کاربران مارکت ثبت و مدیریت می شوند و اکسکوینومارکت نقشی در این خصوص ایفا نمی نماید و کاربران ذر این خصوص حق مطرح کردن ادعایی را ندارند.",
        },
        {
          id: "12",
          text: "12- تراکنش هایی که توسط اکسکوینومارکت در خلال انجام سفارشات انجام می شوند، غیر قابل بازگشت هستند و تابع قوانین مربوط به شبکه پیاده سازی شده در تراکنش انجام شده می باشند.",
        },
        {
          id: "13",
          text: "13- واریز، برداشت و معامله ارزهای رایج دنیا از قبیل ریال، دلار، یورو و غیره در اکسکوینومارکت امکانپذیر نمی‌باشد. کاربران اکسکوینومارکت فقط امکان واریز، برداشت و معامله رمزارزهای شبکه بی اس سی (BSC) را در اکسکوینومارکت دارند.",
        },
      ],
    },
    {
      id: "6",
      title: "6. ضوابط مسئولیت و عدم آن",
      desc: [
        {
          id: "1",
          text: "1- آشنایی کامل با بازار رمز ارز بر عهده کاربر است و شرکت هیچ گونه مسئولیتی در قبال ارزش رمز ارزها و نوسانات بازار بر عهده ندارد.",
        },
        {
          id: "2",
          text: "2- هر گونه سهل انگاری کاربر در حفظ اطلاعات امنیتی حساب کاربری خود از جمله گذر واژه، بر عهده شخص کاربر است. شرکت هیچ گونه مسئولیتی در این مورد به عهده نخواهد داشت.",
        },
        {
          id: "3",
          text: "3- هر گونه پیامدهای مالیاتی ناشی از تراکنش های کاربر با شرکت و یا میان کاربران به عهده ی خود کاربران خواهد بود و شرکت هیچ گونه مسئولیتی نمی پذیرد.",
        },
        {
          id: "4",
          text: "4- شرکت هیچ گونه مسئولیتی راجع به تاخیر، زمان بندی سایت مقصد در دریافت و یا تراکنش ناموفق ایجاد شده در انجام سفارش به هر علت اعم از نقص یا مشکل یا تعمیرات سیستم رمز ارز نمی پذیرد.",
        },
        {
          id: "5",
          text: "5- کاربر سایت می پذیرد که اکسکوینومارکت صرفاً مسئول جابجایی مقدار مشخص شده در معامله معین (سفارش خرید یا فروش) است و هیچ گونه مسئولیت دیگری ندارد.",
        },
        {
          id: "6",
          text: "6- شرکت در حدود قانون و ضوابط و مقررات این سند هیچ گونه تعهد یا ضمانتی برای امور خاص مسکوت مانده در این سند از حیث ارائه‌ی خدمات و در خصوص ارزش رمز ارزهایی که پذیرفته نشده اند، بر عهده نخواهد داشت.",
        },
      ],
    },
    {
      id: "7",
      title: "7. مقررات تکمیلی مسدودی حساب کاربری",
      desc: [
        {
          id: "1",
          text: "1- در صورت مشاهده هر کدام از موارد زیر علاوه بر موارد ذکر شده در بالا، حساب کاربری از سوی اکسکوینو مارکت مسدود خواهد شد:",
          detail: [
            {
              id: "2",
              text: "ارایه دستور کتبی از محاکم قضایی، نهادهای دولتی و سایر نهادهای ذیصلاح،",
            },
            {
              id: "3",
              text: "عدم رعایت ضوابط و شرایط این سند،",
            },
            {
              id: "4",
              text: "عدم پایبندی به قوانین جمهوری اسلامی ایران و آیین نامه ها و دستورالعمل های مربوطه،",
            },
            {
              id: "5",
              text: "در صورت هر گونه دسترسی غیر قانونی شخص ثالث به سرور و استفاده غیر قانونی از آن،",
            },
            {
              id: "6",
              text: "هرگونه مداخله در بلاکچین رمز ارزها با قصد فریب شبکه یا اکسکوینو مارکت.",
            },
          ],
        },
        {
          id: "7",
          text: "2- در کلیه موارد فوق، حق شرکت برای پیگیری قانونی تخلفات کاربر از مراجع ذیصلاح محفوظ می باشد.",
        },
      ],
    },
    {
      id: "8",
      title: "8. حریم خصوصی",
      desc: [
        {
          id: "1",
          text: "برای مشاهده شرایط حریم خصوصی لطفا به بخش <a href=\"https://www.excoino.com/privacy-policy\" target=\"_blank\">مربوطه</a> مراجعه فرمایید.",
        },
      ],
    },
  ],
};
