export default {
    confirmTermsOfUse: "I Accept",
    denyTermsOfUse: "I Do Not Accept",
    title: "Terms and Conditions of Use",
    subTitle: "Arian Data Development Company",
    terms: [
        {
            id: "1",
            title: "1. Introduction",
            desc: [
                {
                    id: "1",
                    text: "Arian Data Development Company (private joint-stock) with national ID 14008195510 outlines the terms and conditions for using the Excoino Market services in this document. This product is designed to provide cryptocurrency services to users and will provide services to users in accordance with the following terms and conditions. The present terms act as a definitive and enforceable contract, in compliance with Articles 10 and 219 of the Civil Code, governing the relationships between users and the company. Users' entry to Excoino Market constitutes acceptance of all the terms and conditions of this document. Therefore, it is essential to carefully read these terms and conditions before using the company's services."
                }
            ]
        },
        {
            id: "2",
            title: "2. Definitions and Terms",
            desc: [
                {
                    id: "1",
                    text: "1- Company: Refers to Arian Data Development Company (private joint-stock) with national ID 14008195510, the owner of the Excoino Market product."
                },
                {
                    id: "2",
                    text: "2- Excoino Market: A dedicated space at <a href='https://market.excoino.com' target='_blank'>market.excoino.com</a> for buying and selling cryptocurrencies through the Excoino website trading panel, accessible to users."
                },
                {
                    id: "3",
                    text: "3- Cryptocurrency Wallet: A place for storing, depositing, and withdrawing currencies on the blockchain network."
                },
                {
                    id: "4",
                    text: "4- User: An individual who, of their own free will, enters, visits, and uses Excoino Market, and fully complies with the laws and regulations of this document to receive the relevant services from the company."
                },
                {
                    id: "5",
                    text: "5- User Account: An account created by individuals under their full responsibility regarding the accuracy, completeness, and transparency of the provided information, in compliance with the laws of the Islamic Republic of Iran and the terms set by the company in Excoino."
                },
                {
                    id: "6",
                    text: "6- Cryptocurrency: A category of digital money and currencies that use cryptography for financial transactions."
                },
                {
                    id: "7",
                    text: "7- Accepted Cryptocurrency: A cryptocurrency listed and announced by Excoino Market and accepted by the company for buying and selling."
                },
                {
                    id: "8",
                    text: "8- Force Majeure: Refers to any external, unavoidable, uncontrollable, and unforeseen event that is not attributable to the act or omission of either party (the company and the user). Examples include floods, earthquakes, fires, wars, strikes, epidemics, widespread internet outages, power outages, and other related events."
                }
            ]
        },
        {
            id: "3",
            title: "3. General",
            desc: [
                {
                    id: "1",
                    text: "1- By entering the Excoino Market panel, the user agrees that they have fully and accurately read, accepted, and agreed to the terms and conditions of this document. Therefore, any future objections or claims of ignorance or any other issues raised by the user in this regard are invalid."
                },
                {
                    id: "2",
                    text: "Note: Any sign, number, word, image, or electronic mark that verifies the identity of the signer and is attached to this document or other related documents is considered an electronic signature and has the same validity and credibility as a handwritten signature. It obligates the signer to the terms of the document, and any proof to the contrary is inadmissible."
                },
                {
                    id: "3",
                    text: "2- The company is authorized to make changes to the terms of this document at any time, either wholly or partially. Such changes will be communicated to the user through appropriate means. If the user continues to use Excoino Market services after being informed, it constitutes acceptance of all changes by the users."
                },
                {
                    id: "4",
                    text: "3- The company provides the following services to users in Excoino Market:",
                    detail: [
                        {
                            id: "5",
                            text: "Buying and selling accepted cryptocurrencies among users,"
                        },
                        {
                            id: "6",
                            text: "Depositing and withdrawing accepted cryptocurrencies,"
                        },
                        {
                            id: "7",
                            text: "Transferring accepted cryptocurrencies to and from Excoino."
                        }
                    ]
                },
                {
                    id: "8",
                    text: "4- The fees charged in the invoice are not related to network fees and are calculated and collected separately."
                },
                {
                    id: "9",
                    text: "5- The rates for buying and selling cryptocurrencies in Excoino Market are determined by the users. The fee rate for Excoino Market services is shown for each order and is collected from both parties upon transaction completion."
                },
                {
                    id: "10",
                    text: "6- Any fees, commissions, or other costs imposed by the blockchain system or cryptocurrencies on the user are solely the user's responsibility. The user must review these matters before ordering, and such costs have no relation to the company, and the company bears no responsibility for such costs."
                },
                {
                    id: "11",
                    text: "7- The selection of cryptocurrencies for listing and acceptance in Excoino Market is at the company's discretion. The company is authorized to increase or decrease the list of accepted cryptocurrencies at any time."
                },
                {
                    id: "12",
                    text: "8- Excoino Market has no connection with gambling and betting sites. If users use Excoino Market services for such sites, their accounts will be immediately suspended, and legal action will be taken by the company."
                },
                {
                    id: "13",
                    text: "9- If illegal or malicious activities by users cause harm to the company, its products, or its users, the company can pursue criminal and civil actions through legal channels to impose penalties and recover all conceivable damages."
                },
                {
                    id: "14",
                    text: "10- All intellectual property related to Excoino Market is exclusively owned by the company, and the exclusive right to exploit it belongs to the company. Any violation of this ownership will result in legal prosecution."
                },
                {
                    id: "15",
                    text: "11- Any notifications and announcements from Excoino Market will be sent to the email or phone number provided by users during registration at Excoino or through the user account. Users can also contact the address listed in the contact us section for essential information or to provide suggestions and questions."
                },
                {
                    id: "16",
                    text: "12- If the company's services are disrupted due to force majeure, the company bears no responsibility."
                },
                {
                    id: "17",
                    text: "13- If non-standard, unreal, or illegal behavior by the user is identified in relation to activities in Excoino Market, the company will warn the user and suspend their account. Account reactivation requires re-verification of identity and proper user activity as determined by the company. If the behavior is illegal or contrary to laws and regulations, appropriate action will be taken in accordance with relevant laws, and the matter will be reported to competent authorities."
                },
                {
                    id: "18",
                    text: "14- Any use of the Excoino Market name and logo without prior written permission from the company is legally actionable."
                },
                {
                    id: "19",
                    text: "15- Deposits and withdrawals of currencies and transfers from Excoino to Excoino Market and vice versa are possible."
                },
                {
                    id: "20",
                    text: "16- All notification messages from Excoino Market are sent from the dedicated Excoino number 10000007000777 and the email address no-reply@excoino.com. The company bears no responsibility for messages sent from numbers or emails other than those mentioned."
                },
                {
                    id: "21",
                    text: "17- To use Excoino Market services, you must register and create a user account at Excoino."
                },
                {
                    id: "22",
                    text: "18- The company is authorized to request additional information from users at any time to verify their identity and transactions. Users must provide the required information; otherwise, the company is authorized to suspend the user account."
                },
                {
                    id: "23",
                    text: "19- By entering the Excoino Market panel, the user agrees to comply with all laws of the Islamic Republic of Iran, including the Anti-Money Laundering Law, while using the services. Otherwise, the user is solely responsible for any criminal and civil liabilities."
                },
                {
                    id: "24",
                    text: "20- The user account is personal, and sharing the account with others in any form (renting bank accounts, user information, etc.) is prohibited. If such cases are identified, the user's account will be suspended, and all consequences and responsibilities will be borne by the user."
                }
            ]
        },
        {
            id: "4",
            title: "4. User Obligations",
            desc: [
                {
                    id: "1",
                    text: "1- The user agrees that the origin and destination of all cryptocurrencies provided by them in Excoino Market transactions fully comply with legal regulations and the laws of the Islamic Republic of Iran."
                },
                {
                    id: "2",
                    text: "2- The user must carefully read and be fully aware of the transaction fee before trading. Any objections to the deduction of fees or lack of knowledge about the fee amount will not be accepted by the company, and the company bears no responsibility in this regard."
                },
                {
                    id: "3",
                    text: "3- The user must register their order with complete accuracy. In case of any claimed errors, cancellation or refund of the currency is not possible, and the company bears no responsibility for such user errors and is not liable."
                },
                {
                    id: "4",
                    text: "4- Due to the nature of the blockchain network, each cryptocurrency wallet address is unique to that cryptocurrency. Due to the similarity of some cryptocurrency addresses, if another cryptocurrency is mistakenly deposited to the specified address, all responsibility for the error lies with the user, and the company bears no responsibility for user errors."
                },
                {
                    id: "5",
                    text: "5- The user acknowledges and agrees that they are aware of the investment risks in the digital currency domain and engage in investment and cryptocurrency exchange with this knowledge."
                },
                {
                    id: "6",
                    text: "6- The user must ensure the accuracy of the wallet address and any related information when depositing (recharging) any currency to their wallet. In case of negligence and depositing or recharging to an incorrect address or any related issues, or a different network than provided by Excoino Market (BSC network), the company bears no responsibility for user errors, and the user waives any right to claim or object regarding their own errors."
                },
                {
                    id: "7",
                    text: "7- To ensure the security and accuracy of transactions, the use of any unauthorized tools, bots, or suspicious trading activities as determined by Excoino Market is prohibited. If a user engages in such activities, all related transactions will be deemed unacceptable, canceled, and void of any financial or legal effect. Only the principal user assets will be refunded, and the user's account will be deactivated."
                },
                {
                    id: "8",
                    text: "8- The user agrees that all their activities and those of the company are subject to the laws of the Islamic Republic of Iran and that their activities must not violate these laws. In case of any violation, financial or non-financial misuse, or suspicious activities by any method, the user's account will be suspended for review (at least 48 to 72 working hours). If a violation is detected, the user's identity and financial information will be provided to the relevant legal authorities, and the user will be liable for all related damages without any conditions or claims."
                }
            ]
        },
        {
            id: "5",
            title: "5. Transaction Conditions",
            desc: [
                {
                    id: "1",
                    text: "1- If there are any reasons for ambiguity or suspicion in transactions, or violations of laws or regulations, the company is authorized to void the transaction without legal recourse and return the assets of both parties. Users waive any right to claim, object, or refer to relevant authorities in this regard."
                },
                {
                    id: "2",
                    text: "2- To prevent any violations or breaches of financial regulations, the responsibility for each transaction lies with the user. It is assumed that the user ensures the security of their account and does not share their account information with others."
                },
                {
                    id: "3",
                    text: "3- Excoino Market bears no responsibility for deposits/withdrawals of tokens or cryptocurrencies on incorrect platforms or networks not supported by Excoino Market. Users are solely responsible for any incorrect deposits/withdrawals and waive any right to claim in this regard."
                },
                {
                    id: "4",
                    text: "4- Orders executed by Excoino Market, with transaction or tracking numbers registered, are considered complete by Excoino Market."
                },
                {
                    id: "5",
                    text: "5- If Excoino Market mistakenly credits a user's account or makes any calculation errors, the company is authorized to correct the error at any time without any administrative or legal procedures. By accepting this document, the user grants permission to the company for such actions and waives any right to object or claim regarding the company's operations."
                },
                {
                    id: "6",
                    text: "6- Fees charged by the company are final, non-refundable, and non-reversible under any circumstances."
                },
                {
                    id: "7",
                    text: "7- If the company mistakenly credits a user's account or makes any errors in calculations or rates, the user permits the company to correct the error at any time without any administrative or legal procedures, and without requiring further user consent. The company's determination of the error or unauthorized payment will be valid, and the user waives any right to object or claim regarding the company's operations."
                },
                {
                    id: "8",
                    text: "8- In case of any suspicious deposits/withdrawals/transactions, they will be reviewed within 48 to 72 working hours."
                },
                {
                    id: "9",
                    text: "9- Generally, currency deposits are instant. Cryptocurrency withdrawal processes or transfers to Excoino are typically completed within 45 minutes after request registration during working hours (9-17) and up to 2 hours during non-working hours (17-24) and official holidays. This timeline applies under normal conditions, and delays may occur during force majeure events, high order volumes, market fluctuations, or network traffic. The user is authorized to follow up on transaction accuracy but cannot claim any damages in such cases."
                },
                {
                    id: "10",
                    text: "10- In a transaction, the recipient's identity may be the same as the sender's. Excoino Market does not verify the identity of the recipient and sender and bears no responsibility for the relationship between the buyer and seller. The company has no intermediary or arbitration role in such cases and is not liable."
                },
                {
                    id: "11",
                    text: "11- Excoino Market bears no responsibility for redeeming tokens, and all prices listed in Excoino Market are registered and managed by market users. Excoino Market plays no role in this regard, and users have no right to claim."
                },
                {
                    id: "12",
                    text: "12- Transactions conducted by Excoino Market during order execution are non-reversible and subject to the laws of the implemented transaction network."
                },
                {
                    id: "13",
                    text: "13- Deposits, withdrawals, and trading of fiat currencies such as Rial, Dollar, Euro, etc., are not possible in Excoino Market. Users can only deposit, withdraw, and trade cryptocurrencies on the BSC network in Excoino Market."
                }
            ]
        },
        {
            id: "6",
            title: "6. Liability and Disclaimers",
            desc: [
                {
                    id: "1",
                    text: "1- The user is fully responsible for understanding the cryptocurrency market, and the company bears no responsibility for the value of cryptocurrencies and market fluctuations."
                },
                {
                    id: "2",
                    text: "2- Any negligence by the user in maintaining the security information of their account, including passwords, is the user's responsibility, and the company bears no responsibility in this regard."
                },
                {
                    id: "3",
                    text: "3- Any tax implications resulting from transactions between the user and the company or among users are the users' responsibility, and the company bears no responsibility."
                },
                {
                    id: "4",
                    text: "4- The company bears no responsibility for delays, timing issues of the destination site, or unsuccessful transactions caused by any reason, including system defects, issues, or maintenance of the cryptocurrency system."
                },
                {
                    id: "5",
                    text: "5- The user agrees that Excoino Market is solely responsible for transferring the specified amount in the defined transaction (purchase or sale order) and bears no other responsibility."
                },
                {
                    id: "6",
                    text: "6- The company, within the scope of the law and the regulations of this document, bears no obligation or warranty for unspecified matters in this document regarding the provision of services or the value of unaccepted cryptocurrencies."
                },
            ]
        },
        {
            id: "7",
            title: "7. Additional Account Suspension Regulations",
            desc: [
                {
                    id: "1",
                    text: "1- In the event of observing any of the following cases in addition to the aforementioned cases, the user account will be suspended by Excoino Market:",
                    detail: [
                        {
                            id: "2",
                            text: "Providing a written order from judicial courts, government institutions, and other competent authorities,"
                        },
                        {
                            id: "3",
                            text: "Non-compliance with the rules and conditions of this document,"
                        },
                        {
                            id: "4",
                            text: "Failure to adhere to the laws of the Islamic Republic of Iran and related regulations and directives,"
                        },
                        {
                            id: "5",
                            text: "Any unauthorized access by a third party to the server and illegal use of it,"
                        },
                        {
                            id: "6",
                            text: "Any interference with the cryptocurrency blockchain with the intent to deceive the network or Excoino Market."
                        }
                    ]
                },
                {
                    id: "7",
                    text: "2- In all of the above cases, the company's right to legally pursue user violations from the competent authorities is reserved."
                }
            ]
        },
        {
            id: "8",
            title: "8. Privacy Policy",
            desc: [
                {
                    id: "1",
                    text: "To view the privacy policy, please visit the <a href=\"https://www.excoino.com/privacy-policy\" target=\"_blank\">relevant section</a>."
                }
            ]
        }
    ]
}
        