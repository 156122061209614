export default {
  preconditionsHistory: "تاريخ أوامر الحد/الإيقاف",
  filter: "تصفية",
  trxRangeDate: "نطاق تاريخ المعاملة",
  pairCoin: "زوج العملات",
  orderType: "نوع الأمر",
  direction: "اتجاه السوق",
  buy: "شراء",
  sell: "بيع",
  status: "الحالة",
  search: "بحث",
  clearFilters: "مسح الفلاتر",
  marketPrice: "سعر السوق",
  stopLimit: "حد/إيقاف",
  oco: "OCO",
  orderDetails: "تفاصيل الأمر",
  time: "الوقت",
  type: "نوع",
  price: "السعر",
  amount: "الكمية",
  turnOver: "حجم التداول",
  ocoStopLimit: "حد/إيقاف OCO",
  tradeDirection: "اتجاه التجارة",
  activationPrice: "سعر التفعيل",
  description: "الوصف",
  completed: "مكتمل",
  ordered: "تم الطلب",
  canceled: "ملغى",
  refCode: "رمز المرجع",
  operation: "عملية",
  details: "تفاصيل"
};
