export default {
    assets: "Assets",
    totalAssets: "Total Asset Value",
    crypto: "Cryptocurrency",
    balance: "Total Balance",
    irrBalance: "IRR Balance Equivalent",
    usdBalance: "USD Balance Equivalent",
    showUsdBalance: "Show Balance in USD",
    showIrrBalance: "Show Balance in IRR",
    showAvailableAsset: "Hide Zero Balance",
    frozen: "Frozen Balance",
    operation: "Operation",
    disabledDeposit: "You cannot request a deposit, please contact support!",
    deposit: "Deposit",
    withdraw: "Withdraw",
    transfer: "Transfer",
    disabledWithdraw: "You cannot request a withdrawal, please contact support!",
    disabledTransfer: "You cannot request a transfer, please contact support!",
    trade: "Trade",
    checkWallet: "Update Balance",
    modalText01: "The demo market is an educational and testing market that allows you to trade with virtual currencies like V-USDT, V-BTC, and V-ETH. These currencies are only for testing and learning purposes and have no real value. You cannot convert them to Rial or Dollar or withdraw them from the system. You can only exchange them in the demo market with each other.",
    modalText02: "All users receive some V-USDT for free after registration. You can use this currency to place buy or sell orders for other currencies in the demo market. For example, you can convert your V-USDT to V-BTC. To do this, you need to enter the desired price and quantity and then confirm your order. The system will automatically match your order with suitable orders from other users and execute the trade.",
    modalText03: "You can use this market to familiarize yourself with the system's capabilities without risking your real assets and then proceed to real trading.",
    demoTrading: "Demo Trading",
    trialCurrency: "trial currency"
  };
  