import BaseIcon from "../../UI/BaseIcon/index";
export default {
  name: "header-nav-mobile",
  components: { BaseIcon },
  props: {
    showDrawer: {
      type: Boolean,
    },
    activeLinkName: {
      type: String,
    },
    isUserLogin: {
      type: Boolean,
    },
    isWithdrawActive: {
      type: Boolean,
    },
    // isDepositActive: {
    //   type: Boolean,
    // },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_EXCOINO_PANEL_URL,
      publicBaseUrl: process.env.VUE_APP_EXCOINO_PUBLIC_URL,
    };
  },
  computed: {
    isDrawerOpen: {
      get() {
        return this.showDrawer;
      },
      set() {
        this.$emit("onToggleDrawer");
      },
    },
  },
  mounted() {},
  methods: {
    closeDrawer() {
      if (this.isDrawerOpen) {
        this.isDrawerOpen = false;
      }
    },
    onClickLogout() {
      this.$emit("onLogout");
    },
    goToExcoinoAuth(e) {
      e.preventDefault();
      window.location = `${this.baseUrl}/auth?marketRedirect=${this.$route.path}`;
    },
  },
};
