import AppLogo from "../AppLogo";
import HeaderNav from "../HeaderNav";
import AccountMenu from "../AccountMenu";
import NotifMenu from "../NotifMenu";
import {mapGetters} from "vuex";

export default {
    name: "app-header",
    components: {
        AppLogo,
        HeaderNav,
        AccountMenu,
        NotifMenu,
    },
    props: {
        activeLinkName: {
            type: String,
        },
    },
    data() {
        return {
            baseUrl: process.env.VUE_APP_EXCOINO_PANEL_URL,
            publicBaseUrl: process.env.VUE_APP_EXCOINO_PUBLIC_URL,
            selectedLang: {symbol: 'fa', fullName: "فارسی"},
            isOpenLanguageModal: false
        };
    },
    computed: {
        ...mapGetters({
            getLoginStatus: "global/IS_LOGIN",
            getWithdrawStatus: "global/GET_WITHDRAW_STATUS",
            getDepositStatus: "global/GET_DEPOSIT_STATUS",
        }),
        isLogin() {
            return this.getLoginStatus;
        },
        withdrawStatus() {
            return this.getWithdrawStatus;
        },
        depositStatus() {
            return this.getDepositStatus;
        },
    },
    mounted() {
        const currentLang = window.localStorage.getItem("lang")
        if (currentLang) {
            this.$i18n.locale = currentLang
            this.selectedLang.symbol = currentLang
            switch (currentLang) {
                case 'fa':
                    this.selectedLang.fullName = "فارسی"
                    break
                case 'en':
                    this.selectedLang.fullName = "English"
                    break
                default :
                    this.selectedLang.fullName = ""
            }
        } else {
            window.localStorage.setItem("lang", "fa")
        }
    },
    methods: {
        onClickMenu() {
            this.$emit("onToggleDrawer");
        },
        goToExcoinoAuth() {
            window.location = `${this.baseUrl}/panel/auth/login?marketRedirect=${this.$route.path}`
        },
        changeLang: function (newLang) {
            this.selectedLang.symbol = newLang
        },
        confirmChangeLanguage(lang) {
            // this.$i18n.locale = this.selectedLang.symbol
            this.$i18n.locale = lang
            window.localStorage.setItem("lang", this.$i18n.locale)
            this.isOpenLanguageModal = false
            this.$router.replace({query: {}}).finally(() => {
                this.$router.go()
            })

        },
        toggleModal() {
            this.isOpenLanguageModal = !this.isOpenLanguageModal
        }
    },
    watch: {
        '$route.query': {
            immediate: true,
            handler(newQuery) {
                if (newQuery.lang) {
                    window.localStorage.setItem("lang", newQuery.lang)
                    this.$i18n.locale = newQuery.lang
                    this.selectedLang.symbol = newQuery.lang
                    switch (newQuery.lang) {
                        case 'fa':
                            this.selectedLang.fullName = "فارسی"
                            break
                        case 'en':
                            this.selectedLang.fullName = "English"
                            break
                    }
                }
            }
        }
    }
};
