export default {
  transactions: "المعاملات",
  filter: "تصفية",
  trxRangeTime: "نطاق وقت المعاملة",
  crypto: "العملة المشفرة",
  trxType: "نوع المعاملة",
  search: "بحث",
  clearFilters: "مسح الفلاتر",
  selectRangeTime: "يرجى تحديد نطاق وقت المعاملة",
  trxTime: "وقت المعاملة",
  amount: "المبلغ",
  fee: "الرسوم",
  networkFee: "رسوم الشبكة",
  status: "الحالة",
  completed: "مكتمل"
};
