export default {
    preconditionsHistory: "Stop/Limit Orders History",
    filter: "Filter",
    trxRangeDate: "Transaction Date Range",
    pairCoin: "Pair Coin",
    orderType: "Order Type",
    direction: "Market Direction",
    buy: "Buy",
    sell: "Sell",
    status: "Status",
    search: "Search",
    clearFilters: "Clear Filters",
    marketPrice: "Market Price",
    stopLimit: "Stop/Limit",
    oco: "OCO",
    orderDetails: "Order Details",
    time: "Time",
    type: "Type",
    price: "Price",
    amount: "Amount",
    turnOver: "Trade Volume",
    ocoStopLimit: "OCO Stop/Limit",
    tradeDirection: "Trade Direction",
    activationPrice: "Activation Price",
    description: "Description",
    completed: "Completed",
    ordered: "Ordered",
    canceled: "Canceled",
    refCode: "Reference Code",
    operation: "Operation",
    details: "Details"
  };
  