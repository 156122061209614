export default {
    preconditionsHistory: "تاریخچه حد سود/ضرر",
    filter: "فیلتر",
    trxRangeDate: "بازه زمانی تراکنش",
    pairCoin: "جفت ارز",
    orderType: "نوع سفارش",
    direction: "جهت بازار",
    buy: "خرید",
    sell: "فروش",
    status: "وضعیت",
    search: "جستجو",
    clearFilters: "پاک کردن فیلترها",
    marketPrice: "قیمت بازار",
    stopLimit: "حد سود/ضرر",
    oco: "OCO",
    orderDetails: "جزییات سفارش",
    time: "زمان",
    type: "نوع",
    price: "قیمت",
    amount: "مقدار",
    turnOver: "حجم معاملات",
    ocoStopLimit: "حد سود/ضرر (OCO)",
    tradeDirection: "جهت معامله",
    activationPrice: "قیمت فعال‌سازی",
    description: "توضیحات",
    completed: "تکمیل شده",
    ordered: "سفارش شده",
    canceled: "لغو شد",
    refCode: "کد پیگیری",
    operation: "عملیات",
    details: "جزییات"
}