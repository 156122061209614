export default {
  name: "back-to-top",
  components: {},
  props: [],
  data() {
    return {
      showToTop: false,
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (typeof window === "undefined") return;
      const top = window.scrollY || 0;
      this.showToTop = top > 300;
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
