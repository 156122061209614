export default {
    userAccount: "حساب کاربری",
    userName: "نام کاربری:",
    email: "ایمیل:",
    phoneNumber: "شماره موبایل:",
    termsOfUse: "قوانین و مقررات",
    termsOfUseTip: "شرایط و مقررات استفاده از خدمات شرکت توسعه فناوری آرین داده پیوسته ایرانیان",
    view: "مشاهده",
    loginPss: "رمز عبور",
    loginTip: "مطابق با رمز عبور کاربر در سایت اکسکوینو می‌باشد.",
    change: "تغییر",
    withdrawPss: "رمز برداشت امن",
    withdrawTip: "وارد کردن رمز برداشت امن، برای برداشت از اکسکوینومارکت ضروری است",
    transferPss: "رمز انتقال امن",
    transferTip: "وارد کردن رمز انتقال امن، برای انتقال از اکسکوینومارکت ضروری است",
    set: "تنظیم",
    confirmWithdrawPss: "رمز برداشت امن را تأیید کنید",
    confirmTransferPss: "رمز انتقال امن را تأیید کنید",
    save: "ذخیره",
    reset: "بازنشانی",
    currentWithdrawPss: "رمز برداشت امن فعلی",
    newWithdrawPss: "رمز برداشت امن جدید",
    confirmNewWithdrawPss: "تکرار رمز برداشت امنِ جدید",
    currentTransferPss: "رمز انتقال امن فعلی",
    newTransferPss: "رمز انتقال امن جدید",
    confirmNewTransferPss: "تکرار رمز انتقال امنِ جدید",
    forgotPss: "رمز خود را فراموش کرده‌اید؟",
    confirmationCode: "کد تأیید",
    smsCode: "کد تأیید پیام کوتاه",
    loginCharacterLimits: "لطفا حداقل 6 کاراکتر برای رمز ورود جدید وارد کنید",
    conflictLoginPss: "رمز ورود به سیستم جدید متناقض است",
    withdrawCharacterLimits: "لطفاً حداقل 6 کاراکتر برای رمز برداشت امن وارد کنید",
    enterWithdrawRepeat: "تکرار رمز برداشت امن جدید را وارد کنید",
    conflictWithdrawPss: "تکرار رمز برداشت امن مطابقت ندارد",
    enterTransferPss: "لطفاً رمز انتقال امن فعلی وارد کنید",
    transferCharacterLimits: "لطفاً حداقل 6 کاراکتر برای رمز انتقال امن وارد کنید",
    enterTransferRepeat: "تکرار رمز انتقال امن جدید را وارد کنید",
    conflictTransferPss: "تکرار رمز انتقال امن مطابقت ندارد",
    getCode: "دریافت کد",
    enterEmail: "لطفا ایمیل وارد کنید",
    enterCode: "لطفا کد تأیید را وارد کنید",
    enterPhoneNumber: "لطفا شماره تلفن را وارد کنید",
    enterLoginPss: "لطفاً رمز ورود اصلی را وارد کنید",
    enterRealName: "لطفا نام واقعی خود را وارد کنید",
    enterId: "لطفا شماره شناسه را وارد کنید",
    bindPhoneTip: "لطفاً ابتدا شماره تلفن خود را متصل کنید",
    resetWithdrawPss: "رمز عبور وجوه را بازنشانی کنید",
    resetTransferPss: "رمز عبور وجوه را بازنشانی کنید",
    successSave: "با موفقیت ذخیره شد",
    fillFields: "فیلد های مربوطه باید پر شوند!",
    confirm: "تایید",
    registerToGetCode: "جهت دریافت کد تائید شماره موبایل خود را در حساب کاربر اکسکوینو ثبت کنید.",
    totalAssetValue:"ارزش کل دارایی ها"
}