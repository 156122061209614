import BaseIcon from "../../UI/BaseIcon/index";
export default {
  name: "app-logo",
  components: {
    BaseIcon,
  },
  props: [],
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
