import {numberWithCommas} from "./filters"

export const handleInput = (num, scale) => {
    let number = removeThousand(num);
    return numberWithCommas(number, scale);
}

export const validateInteger = (int) => {
    const str = int.toString();
    return str[1] ? (str[0] === "0" && str[1] !== "." ? "" : str) : str;
}

export const checkDecimalLength = (number, scale) => {
    let floatingNumber = number.toString().split(".")[1];
    let floatingNumberLength = number.toString().split(".")[1].length;
    let integerNumber = validateInteger(number.toString().split(".")[0]);
    floatingNumber = floatingNumberLength > scale ? floatingNumber.slice(0, scale) : floatingNumber;
    if (scale >= 1 || (scale === undefined && floatingNumberLength)) number = integerNumber + "." + floatingNumber;
    else number = integerNumber
    return number;
}

export const removeThousand = (value) => {
    const str = value.toString();
    return str.replace(/,/gi, "");
}
