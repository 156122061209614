export default {
    ordersHistory: "Orders History",
    filter: "Filter",
    trxRangeDate: "Transaction Date Range",
    pairCoin: "Pair Coin",
    orderType: "Order Type",
    direction: "Market Direction",
    buy: "Buy",
    sell: "Sell",
    status: "Status",
    search: "Search",
    clearFilters: "Clear Filters",
    limit: "Limit",
    market: "Market",
    stopLimit: "Stop/Limit",
    oco: "OCO",
    marketPrice: "Market Price",
    orderDetails: "Order Details",
    time: "Time",
    type: "Type",
    ocoLimit: "OCO Limit",
    marketOrder: "Market Order",
    price: "Price",
    amount: "Amount",
    turnOver: "Trade Volume",
    ocoStopLimit: "OCO Stop/Limit",
    tradeDirection: "Trade Direction",
    activationPrice: "Activation Price",
    description: "Description",
    tradedAmount: "Traded Amount",
    averageTradePrice: "Average Trade Price",
    finished: "Finished",
    canceled: "Canceled",
    expired: "Expired",
    refCode: "Reference Code",
    operation: "Operation",
    details: "Details"
  };
  