export default {
  status_active: "نشط",
  status_cancel: "ملغى",
  status_terminated: "منتهي",
  noData_table: "لا توجد بيانات متاحة",
  cancel_plan: "الانسحاب من الخطة",
  cancel_plan_text:
    "بالانسحاب من الخطة، لن تتلقى مكافأة الفترة الحالية وقد يتم فرض رسوم انسحاب من خطة الستاكينج. نوصي بالبقاء حتى نهاية الفترة الحالية للحصول على المكافآت. هل أنت متأكد أنك تريد الانسحاب من الخطة؟",
  back: "العودة",
  activate_auto_renewal: "تفعيل التجديد التلقائي",
  deactivate_auto_renewal: "إلغاء التجديد التلقائي",
  active_auto_renewal_text:
    "مع التجديد التلقائي، عند نهاية فترة الستاكينج، سيتم تحويل أصولك المستاكه إلى الفترة التالية. لاحظ أن مكافأة الستاكينج لكل فترة سيتم تحريرها في نهاية تلك الفترة وإيداعها في محفظتك. لن يتم تحويل مبلغ المكافأة إلى فترة الستاكينج التالية.",
  inactive_auto_renewal_text:
    "بإلغاء التجديد التلقائي، عند نهاية فترة الستاكينج، سيتم تحرير أصولك المستاكه والمكافآت، ولن يتم تضمينك في فترة الستاكينج التالية.",
  activate: "تفعيل",
  deactivate: "إلغاء",
  staking_plans: "خطط الستاكينج",
  success_request: "تم تسجيل طلبك بنجاح.",
  unknown_mistake: "خطأ غير معروف",
  my_plans: "ستاكينج",
  gain_reward: "ستاكينج",
  gain_reward_text:
    "من خلال المشاركة في مختلف خطط الستاكينج لـ Excoino، يمكنك الحصول على مكافآت من خلال الستاكينج بأصولك المشفرة. لقد سهلت Excoino عملية الستاكينج لتوفير فرص مختلفة لمستخدميها.",
  plan_duration: "مدة الخطة:",
  day: "يوم",
  plan_status: "حالة الخطة:",
  exist: "نشط",
  not_exist: "غير نشط",
  approximate_active_reward: "مكافأة تقريبية نشطة، APR (سنوي)",
  rewards_earned: "المكافآت المكتسبة حتى الآن",
  participation_rate: "نسبة المشاركة الخاصة بك",
  cancel_fee: "رسوم الانسحاب",
  renewal_fee: "رسوم التجديد التلقائي",
  cancel: "انسحاب",
  free: "مجاني",
  remain_duration: "الأيام المتبقية / إجمالي الأيام",
  start_date: "تاريخ الانضمام",
  cancel_date: "تاريخ الانسحاب",
  release_duration: "مدة الانتظار للإفراج",
  cancel_release_duration: "مدة الانتظار للإفراج بعد الانسحاب",
  auto_renewal: "تجديد تلقائي",
  date: "تاريخ",
  reward_percent: "نسبة المكافأة",
  earned_money: "الأرباح اليومية",
  reward_table: "جدول المكافآت",
  yearly_active_apr: "APR نشط سنوي (نسبة العائد السنوي)",
  min_max_apr: "الحد الأدنى/الحد الأقصى APR",
  remained_plan: "الخطة المتبقية",
  registration_time: "موعد التسجيل",
  min_max_input: "الحد الأدنى/الحد الأقصى لمبلغ الإدخال",
  to: "إلى",
  registration_fee: "رسوم التسجيل",
  cancelable: "قابل للإلغاء",
  has: "نعم",
  not_has: "لا",
  reward: "المكافأة",
  daily: "يومي",
  weekly: "أسبوعي",
  monthly: "شهري",
  yearly: "سنوي",
  balance: "الرصيد",
  increase_balance: "زيادة الرصيد",
  stake_amount: "مبلغ الستاكينج",
  insufficient_balance: "رصيد غير كافٍ",
  insufficient_min_amount: "أقل من المبلغ المسموح",
  insufficient_max_amount: "أكثر من المبلغ المسموح",
  terms_1: "أنا أوافق على",
  terms_2: "الشروط والأحكام",
  stake: "ستاكينج",
  apr_reward: "APR أو المكافأة",
  apr_reward_text:
    "يشير إلى معدل المكافأة لاستثمار على مدار عام واحد. على سبيل المثال، إذا استثمرت 1000 توكن مع APR 10٪، سيكون لديك 1050 توكن بعد 6 أشهر و1100 توكن في نهاية العام.",
  fixed_stake: "نسبة عائد سنوي ثابتة",
  fixed_stake_text:
    "من خلال المشاركة في خطط العائد السنوي الثابت، ستتلقى معدل مستقر تقريبي طوال الفترة.",
  flexible_stake: "نسبة عائد سنوي متغير",
  flexible_stake_text:
    "في هذه الخطط، تضمن Excoino مكافأة دنيا وقد يكون المبلغ أعلى بناءً على الشبكة أو ظروف السوق أو الخطة. يتم حساب المكافآت يوميًا بمعدل النشط اليومي.",
  earned_reward: "المكافآت (حتى الآن)",
  remained_time: "الوقت المتبقي",
  hour: "ساعة",
  minute: "دقيقة",
  view_detail: "عرض التفاصيل",
  fixed_apr: "APR ثابت",
  max_apr: "APR أقصى",
  active_apr: "APR نشط",
  min_apr: "APR أدنى",
  registered_plan: "أنت مسجل في هذه الخطة",
  min_stake_amount: "الحد الأدنى لمبلغ الستاكينج",
  show_more: "عرض المزيد",
  registration_expired: "انتهى التسجيل",
  registration_not_started: "لم يبدأ التسجيل",
  filled_capacity: "تم ملء السعة",
  enter_plan: "الانضمام إلى الخطة",
  login: "تسجيل الدخول",
  cancel_reward_earned: "المكافأة المكتسبة",
  released_amount: "المبلغ المحرر",
  max_capacity: "السعة الإجمالية",
  renewal_table: "سجلات التجديد",
  renewal_date: "تاريخ التجديد",
  renewal_status: "الحالة",
  instant: "فوري",
  stake_in_plan: "ستاكينج في الخطة",
  my_stakes: "ستاكينجي",
  plan_name: "اسم الخطة",
  plan_status_table: "حالة الخطة"
};
