export default {
    openOrders: "سفارشات باز",
    stopLimitOrders: "حد سود/ضرر",
    pair: "جفت ارز",
    price: "قیمت",
    cancelOrder: "لغو سفارش",
    orderType: "نوع سفارش",
    sell: "فروش",
    buy: "خرید",
    orderDate: "تاریخ سفارش",
    activationPrice: "قیمت فعال‌سازی",
    details: "جزئیات",
    stopLimit: "حد سود/ضرر",
    limitOrder: "محدود",
    marketOrder: "آنی",
    limit: "محدود",
    ocoLimit: "محدود (OCO)",
    ocoStopLimit: "حد سود/ضرر (OCO)",
    marketWallet: "کیف پول مارکت",
    transferToExcoino: "انتقال به اکسکوینو",
    excoinoWallet: "کیف پول اکسکوینو",
    transferToMarket: "انتقال به مارکت",
    marketTip: "این مقدار، نشان‌دهنده مجموع ارزش کیف پول شما در بخش اکسکوینو مارکت می‌باشد. (قابل استفاده در بازار معاملاتی اکسکوینو)",
    viewWallet: "مشاهده کیف پول",
    excoinoTip: "این مقدار، نشان‌دهنده مجموع ارزش کیف پول شما در بخش اکسکوینو می‌باشد. (قابل استفاده در صرافی اکسکوینو)",
    walletsTip: "شما دارای ۲ کیف پول مجزا برای بخش اکسکوینو (OTC) و مارکت (Trading) هستید. برای استفاده از امکانات مارکت می‌بایست دارایی مورد نظر خود را از کیف پول اکسکوینو به مارکت انتقال دهید.",
    noMarketWallets: "کیف پول مارکت شما خالی است.<br>برای انتقال دارایی به مارکت از طریق کیف پول اکسکوینو اقدام کنید.",
    allMarketAssets: "مشاهده همه دارایی‌های مارکت",
    largestVolume: "بیشترین حجم معاملات",
    highestGrowthOfTheDay: "بیشترین رشد روز",
    biggestDropOfTheDay: "بیشترین کاهش روز",
    newestMarkets: "جدید ترین بازار معاملاتی",
    favoriteMarkets: "بازارهای محبوب",
    noOrders: "شما در حال حاضر سفارش باز ندارید.",
    markets: "بازار ها",
    allMarkets: "همه بازار ها",
    noData: "اطلاعاتی برای نمایش وجود ندارد",
    orderCanceled: "سفارش لغو شد",
    marketTransferDesc: "برای انتقال دارایی های اکسکوینو به مارکت باید از طریق صفحه کیف پول های اکسکوینو اقدام نمایید",
    goToExcoinoWallet: "رفتن به کیف پول اکسکوینو",
    transferFromExcoino: "انتقال {unit} از اکسکوینو",
    viewExcoinoWallet: "مشاهده کیف پول اکسکوینو",
    excoinoWalletDesc: "برای مشاهده دارایی های اکسکوینو باید به صفحه کیف پول اکسکوینو مراجعه نمایید."
}