export default {
    dashboard: "Dashboard",
    exchange: "Market Trades",
    markets: "Markets",
    assets: "Assets",
    transactions: "Transactions",
    deposit: "Deposit",
    transfer: "Transfer",
    withdraw: "Withdraw",
    orders: "Orders",
    openOrders: "Open Orders",
    history: "History",
    ordersHistory: "Orders History",
    preconditionOrdersHistory: "Stop/Limit Orders History",
    stopLimitOrders: "Stop/Limit",
    guide: "Guide",
    excoino: "Go to Excoino Main Page",
    excoinoPanel: "Back to Excoino",
    userProfile: "User Profile",
    affiliateProfile: "Refer Friends",
    support: "Support",
    logout: "Logout",
    register: "Register",
    userCenter: "User Center",
    helpCenter: "Support",
    loginRegister: "Login/Register",
    staking: "Staking",
    activeLanguage: "Active languages in Excoino"
  };
  