export default {
    recharge: "واریز",
    symbol: "رمزارز",
    coin: "ارز",
    network: "شبکه",
    address: "آدرس واریز",
    copy: "کپی آدرس",
    qrCode: "اسکن QRCode",
    contactSupport: "شما قادر به ثبت درخواست واریز نیستید، لطفا با پشتیبانی تماس بگیرید!",
    successDeposit: "برای واریز موفقیت آمیز {coin} آدرس {coin} و تگ/ممو را وارد کنید.",
    confirm: "متوجه شدم",
    walletAddress: "آدرس کیف پول {coin} ({network})",
    msg1: "واریز ارز فیات امکان‌پذیر نیست.",
    msg2: "همه‌ ارزهای شبکه BSC آدرس واریز یکسانی دارند. استفاده از این آدرس برای واریز رمزارزهای غیراز {coin} مجاز نیست. در صورت واریز ارزهای دیگر به این آدرس مبلغ واریز شده عودت داده نمی‌شود.",
    msg3: "حداقل مقدار واریز {charge} {coin} است و واریز کمتر از این مقدار محاسبه نمی‌شود.",
    msg4: "آدرس شارژ شما مرتباً تغییر نمی کند و می توانید شارژ را تکرار کنید. در صورت تغییر ، سعی خواهیم کرد تا از طریق اعلامیه وب سایت یا ایمیل به شما اطلاع دهیم.",
    msg5: "لطفاً اطمینان حاصل کنید که امنیت رایانه و مرورگر خود را تأیید کنید تا از دستکاری یا لو رفتن اطلاعات جلوگیری شود.",
    msg6: "مطمئن شوید رمزارز {coin} است.",
    msg7: "مطمئن شوید شبکه {network} است.",
    record: "اطلاعات واریز",
    successCopy: "آدرس با موفقیت کپی شد.",
    errCopy: "خطا！ لطفاً آدرس را دستی کپی کنید.",
    time: "زمان واریز",
    amount: "مقدار",
    getAddress: "دریافت آدرس",
    memoTips: "لطفاً هنگام شارژ مجدد این یادداشت را وارد کنید ، در غیر این صورت دارایی خود را از دست خواهید داد.",
    selectCoin: "لطفا ارز را انتخاب کنید",
    selectNetwork: "لطفا شبکه را انتخاب کنید",
    tagNotice: "تگ/ممو و آدرس برای واریز موفقیت آمیز {iso} مورد نیاز هستند."
}