export default {
    recharge: "Deposit",
    symbol: "Cryptocurrency",
    coin: "Currency",
    network: "Network",
    address: "Deposit Address",
    copy: "Copy Address",
    qrCode: "Scan QRCode",
    contactSupport: "You cannot request a deposit, please contact support!",
    successDeposit: "To successfully deposit {coin}, enter the {coin} address and tag/memo.",
    confirm: "Got it",
    walletAddress: "{coin} Wallet Address ({network})",
    msg1: "Fiat currency deposit is not possible.",
    msg2: "All BSC network cryptocurrencies have the same deposit address. Using this address for depositing cryptocurrencies other than {coin} is not allowed. If other currencies are deposited to this address, the deposited amount will not be refunded.",
    msg3: "The minimum deposit amount is {charge} {coin}, and deposits below this amount will not be processed.",
    msg4: "Your deposit address does not change frequently, and you can repeat deposits. If it changes, we will try to notify you via website announcement or email.",
    msg5: "Please ensure the security of your computer and browser to prevent tampering or data leakage.",
    msg6: "Make sure it is {coin} cryptocurrency.",
    msg7: "Make sure it is {network} network.",
    record: "Deposit Information",
    successCopy: "Address successfully copied.",
    errCopy: "Error! Please copy the address manually.",
    time: "Deposit Time",
    amount: "Amount",
    getAddress: "Get Address",
    memoTips: "Please enter this memo when recharging; otherwise, you will lose your assets.",
    selectCoin: "Please select a currency",
    selectNetwork: "Please select a network",
    tagNotice: "Tag/Memo and address are required for a successful {iso} deposit."
  };
  