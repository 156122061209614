export default {
  filter: "تصفية",
  trxRangeTime: "نطاق وقت المعاملة",
  orderType: "نوع الأمر",
  direction: "اتجاه السوق",
  search: "بحث",
  buy: "شراء",
  sell: "بيع",
  clearFilters: "مسح الفلاتر",
  stopLimit: "حد/إيقاف",
  oco: "OCO",
  marketPrice: "سعر السوق",
  successCancel: "تم إلغاء الأمر بنجاح",
  time: "الوقت",
  ocoStopLimit: "حد/إيقاف OCO",
  pairCoin: "زوج العملات",
  price: "السعر",
  amount: "الكمية",
  tradeDirection: "اتجاه التجارة",
  activationPrice: "سعر التفعيل",
  cancelOrder: "إلغاء الأمر",
  cancelOrderDescription: "هل أنت متأكد أنك تريد إلغاء الأمر؟",
  confirm: "تأكيد",
  cancel: "إلغاء",
  refCode: "رمز المرجع",
  operation: "عملية",
  undo: "تراجع"
};
