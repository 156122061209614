export default {
  SET_PAIR_COIN_SCALES: (state, scalesList) => {
    state.pairCoinScales = scalesList;
  },
  SET_MY_STAKE_DETAIL:(state,payload)=>{
    state.myStakeDetail={}
    state.myStakeDetail=payload
  },
  SET_MY_STAKE_DATA:(state,payload)=>{
    state.myStakeData=[]
    state.myStakeData=payload
  }
};
