// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import store from "./store/index";
import router from "./router";
import i18n from "./plugins/i18n";
import "./assets/style/style.scss";
import "./assets/icons/iconfont.css";
import ViewUI from "view-design";
import "view-design/dist/styles/iview.css";
import locale from "view-design/dist/locale/fa-IR";
import util from "./assets/js/util.js";
import "./utiles/filters";
import "./plugins/toast";
import App from "./pages/App/index.vue";
import Api from "./config/api";
import VClipboard from "v-clipboard";
import VueCookies from "vue-cookies";

Vue.use(VueCookies);
Vue.use(ViewUI, {locale});
Vue.use(VClipboard);
Vue.prototype.rootHost = "https://www.xxxx.com";
// Vue.prototype.host = "http://172.25.1.78";
Vue.prototype.host = process.env.VUE_APP_API_BASE_URL;

Vue.prototype.api = Api;

Vue.mixin({
    computed: {
        isLocaleLTR() {
           return  i18n.locale === "en"
        }
    }
})


Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
