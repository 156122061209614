export default {
  userAccount: "حساب المستخدم",
  userName: "اسم المستخدم:",
  email: "البريد الإلكتروني:",
  phoneNumber: "رقم الهاتف:",
  termsOfUse: "الشروط والأحكام",
  termsOfUseTip: "الشروط والأحكام لاستخدام خدمات Excoino",
  view: "عرض",
  loginPss: "كلمة مرور تسجيل الدخول",
  loginTip: "نفس كلمة المرور على موقع Excoino.",
  change: "تغيير",
  withdrawPss: "كلمة مرور السحب الآمنة",
  withdrawTip: "إدخال كلمة مرور السحب الآمنة ضروري للسحب من سوق Excoino",
  transferPss: "كلمة مرور التحويل الآمنة",
  transferTip: "إدخال كلمة مرور التحويل الآمنة ضروري للتحويلات من سوق Excoino",
  set: "تعيين",
  confirmWithdrawPss: "تأكيد كلمة مرور السحب الآمنة",
  confirmTransferPss: "تأكيد كلمة مرور التحويل الآمنة",
  save: "حفظ",
  reset: "إعادة تعيين",
  currentWithdrawPss: "كلمة مرور السحب الآمنة الحالية",
  newWithdrawPss: "كلمة مرور السحب الآمنة الجديدة",
  confirmNewWithdrawPss: "تأكيد كلمة مرور السحب الآمنة الجديدة",
  currentTransferPss: "كلمة مرور التحويل الآمنة الحالية",
  newTransferPss: "كلمة مرور التحويل الآمنة الجديدة",
  confirmNewTransferPss: "تأكيد كلمة مرور التحويل الآمنة الجديدة",
  forgotPss: "هل نسيت كلمة المرور؟",
  confirmationCode: "رمز التأكيد",
  smsCode: "رمز التأكيد عبر الرسائل القصيرة",
  loginCharacterLimits: "يرجى إدخال ما لا يقل عن 6 أحرف لكلمة المرور الجديدة لتسجيل الدخول",
  conflictLoginPss: "كلمة مرور تسجيل الدخول الجديدة متضاربة",
  withdrawCharacterLimits: "يرجى إدخال ما لا يقل عن 6 أحرف لكلمة مرور السحب الآمنة",
  enterWithdrawRepeat: "يرجى إدخال تكرار كلمة مرور السحب الآمنة الجديدة",
  conflictWithdrawPss: "تكرار كلمة مرور السحب الآمنة لا يتطابق",
  enterTransferPss: "يرجى إدخال كلمة مرور التحويل الآمنة الحالية",
  transferCharacterLimits: "يرجى إدخال ما لا يقل عن 6 أحرف لكلمة مرور التحويل الآمنة",
  enterTransferRepeat: "يرجى إدخال تكرار كلمة مرور التحويل الآمنة الجديدة",
  conflictTransferPss: "تكرار كلمة مرور التحويل الآمنة لا يتطابق",
  getCode: "الحصول على الرمز",
  enterEmail: "يرجى إدخال بريدك الإلكتروني",
  enterCode: "يرجى إدخال رمز التأكيد",
  enterPhoneNumber: "يرجى إدخال رقم هاتفك",
  enterLoginPss: "يرجى إدخال كلمة مرور تسجيل الدخول الأساسية",
  enterRealName: "يرجى إدخال اسمك الحقيقي",
  enterId: "يرجى إدخال رقم الهوية",
  bindPhoneTip: "يرجى ربط رقم هاتفك أولاً",
  resetWithdrawPss: "إعادة تعيين كلمة مرور السحب",
  resetTransferPss: "إعادة تعيين كلمة مرور التحويل",
  successSave: "تم الحفظ بنجاح",
  fillFields: "يجب ملء جميع الحقول ذات الصلة!",
  confirm: "تأكيد",
  registerToGetCode: "لتلقي رمز التأكيد، سجل رقم هاتفك المحمول في حساب المستخدم الخاص بك في Excoino."
};
