export default {
    title: "بازارها",
    loginToAddFavorites: "برای افزودن علاقمندی ها باید از طریق اکسکوینو وارد شوید",
    addToFavorites: "افزودن به علاقه‌مندی‌ها",
    removeFavorites: "لغو علاقه‌مندی",
    custom: "علاقه‌مندی‌ها",
    coin: "جفت‌ارز",
    symbol: "جفت معامله",
    favor: "علاقه‌مندی‌ها‌",
    newPrice: "آخرین قیمت",
    exchangeNum: "حجم معاملات",
    change: "تغییرات روزانه",
    priceTrend: "روند قیمت",
    operate: "عملیات",
    trading: "معامله",
    high: "بالاترین قیمت",
    low: "پایین‌ترین قیمت",
    demoMarket:"بازار آزمایشی",
}