export default {
  SET_TERMS_OF_USE_MODAL_STATUS: (state, showModal) => {
    state.showTermsOfUseModal = showModal;
  },
  SET_DEPOSIT_STATUS: (state, status) => {
    state.depositStatus = status;
  },
  SET_WITHDRAW_STATUS: (state, status) => {
    state.withdrawStatus = status;
  },
  SET_TRANSFER_STATUS: (state, status) => {
    state.transferStatus = status;
  },
  SET_STATUS_CODE: (state, status) => {
    state.responseStatusCode = status;
  },
  SET_TRADING_STATUS: (state, status) => {
    state.tradingStatus = status;
  },
  NAVIGATE: (state, nav) => {
    state.activeNav = nav;
  },
  SET_MEMBER: (state, member) => {
    state.member = member;
    localStorage.setItem("MEMBER", JSON.stringify(member));
    if (!member) {
      localStorage.removeItem("MEMBER");
    }
  },
  RECOVER_MEMBER: (state) => {
    state.member = JSON.parse(localStorage.getItem("MEMBER"));
  },
  SET_LANG: (state, lang) => {
    state.lang = lang;
    localStorage.setItem("LANGUAGE", JSON.stringify(lang));
  },
  INIT_LANG: (state) => {
    if (localStorage.getItem("LANGUAGE") == null) {
      state.lang = "English";
    } else {
      state.lang = JSON.parse(localStorage.getItem("LANGUAGE"));
    }
  },
  INIT_LOGIN_TIMES: (state) => {
    if (localStorage.getItem("LOGINTIMES") == null) {
      state.loginTimes = 0;
    } else {
      state.loginTimes = JSON.parse(localStorage.getItem("LOGINTIMES"));
    }
  },
  SET_LOGIN_TIMES: (state, times) => {
    state.loginTimes = times;
    localStorage.setItem("LOGINTIMES", JSON.stringify(times));
  },
  SET_SKIN: (state, skin) => {
    state.exchangeSkin = skin;
  },
  // MUTATE_CHECK_IS_AUTH: state => {
  //   // if (localStorage.getItem("accessToken")) {
  //   //   await localStorage.setItem("isAuth", "true");
  //   //   state.isAuth = true;
  //   // } else {
  //   //   await localStorage.setItem("isAuth", "false");
  //   //   state.isAuth = false;
  //   // }
  //   localStorage.getItem("isAuth") === "true"
  //     ? (state.isAuth = true)
  //     : (state.isAuth = false);
  // },
};
