export default {
  name: "time-bar",
  components: {},
  props: [],
  data() {
    return {
      utc: null,
      time: null,
    };
  },
  computed: {},
  created() {
    this.InitDate();
  },
  mounted() {},
  methods: {
    InitDate() {
      let d = new Date(),
        gmtHours = d.getTimezoneOffset() / 60;
      this.utc =
        "GMT " + (gmtHours > 0 ? "-" : "+") + " " + String(gmtHours)[1];
      setInterval(() => {
        this.time = new Date().getTime();
      }, 1000);
    },
  },
};
