export default {
    openOrders: "Open Orders",
    filter: "Filter",
    trxRangeTime: "Transaction Time Range",
    symbol: "Cryptocurrency",
    orderType: "Order Type",
    direction: "Market Direction",
    search: "Search",
    buy: "Buy",
    sell: "Sell",
    clearFilters: "Clear Filters",
    limit: "Limit",
    market: "Market",
    stopLimit: "Stop/Limit",
    oco: "OCO",
    successCancel: "Order canceled successfully",
    orderDetails: "Order Details",
    time: "Time",
    type: "Type",
    ocoLimit: "OCO Limit",
    ocoStopLimit: "OCO Stop/Limit",
    pairCoin: "Pair Coin",
    price: "Price",
    amount: "Amount",
    turnOver: "Trade Volume",
    tradeDirection: "Trade Direction",
    activationPrice: "Activation Price",
    description: "Description",
    cancelOrder: "Cancel Order",
    cancelOrderDescription: "Are you sure you want to cancel the order?",
    confirm: "Confirm",
    cancel: "Cancel",
    limitOrder: "Limit Order",
    marketOrder: "Market Order",
    tradedAmount: "Traded Amount",
    tradedPercent: "Traded Percentage",
    averageTradePrice: "Average Trade Price",
    refCode: "Reference Code",
    operation: "Operation",
    undo: "Undo",
    details: "Details"
  };
  