export default {
    transactions: "تراکنش‌ها",
    filter: "فیلتر",
    trxRangeTime: "بازه زمانی تراکنش",
    crypto: "رمز ارز",
    trxType: "نوع تراکنش",
    search: "جستجو",
    clearFilters: "پاک کردن فیلترها",
    selectRangeTime: "لطفا بازه زمانی تراکنش را مشخص نمایید",
    trxTime: "زمان تراکنش",
    amount: "مقدار",
    fee: "کارمزد",
    networkFee: "کارمزد شبکه",
    status: "وضعیت",
    completed: "تکمیل شده"
}