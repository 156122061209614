export default {
    filter: "Filter",
    trxRangeTime: "Transaction Time Range",
    orderType: "Order Type",
    direction: "Market Direction",
    search: "Search",
    buy: "Buy",
    sell: "Sell",
    clearFilters: "Clear Filters",
    stopLimit: "Stop/Limit",
    oco: "OCO",
    marketPrice: "Market Price",
    successCancel: "Order canceled successfully",
    time: "Time",
    ocoStopLimit: "OCO Stop/Limit",
    pairCoin: "Pair Coin",
    price: "Price",
    amount: "Amount",
    tradeDirection: "Trade Direction",
    activationPrice: "Activation Price",
    cancelOrder: "Cancel Order",
    cancelOrderDescription: "Are you sure you want to cancel the order?",
    confirm: "Confirm",
    cancel: "Cancel",
    refCode: "Reference Code",
    operation: "Operation",
    undo: "Undo"
  };
  