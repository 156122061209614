export default {
    transactions: "Transactions",
    filter: "Filter",
    trxRangeTime: "Transaction Time Range",
    crypto: "Cryptocurrency",
    trxType: "Transaction Type",
    search: "Search",
    clearFilters: "Clear Filters",
    selectRangeTime: "Please specify the transaction time range",
    trxTime: "Transaction Time",
    amount: "Amount",
    fee: "Fee",
    networkFee: "Network Fee",
    status: "Status",
    completed: "Completed"
  };
  