export default {
  ordersHistory: "تاريخ الأوامر",
  filter: "تصفية",
  trxRangeDate: "نطاق تاريخ المعاملة",
  pairCoin: "زوج العملات",
  orderType: "نوع الأمر",
  direction: "اتجاه السوق",
  buy: "شراء",
  sell: "بيع",
  status: "الحالة",
  search: "بحث",
  clearFilters: "مسح الفلاتر",
  limit: "حد",
  market: "سوق",
  stopLimit: "حد/إيقاف",
  oco: "OCO",
  marketPrice: "سعر السوق",
  orderDetails: "تفاصيل الأمر",
  time: "الوقت",
  type: "نوع",
  ocoLimit: "حد OCO",
  marketOrder: "أمر سوق",
  price: "السعر",
  amount: "الكمية",
  turnOver: "حجم التداول",
  ocoStopLimit: "حد/إيقاف OCO",
  tradeDirection: "اتجاه التجارة",
  activationPrice: "سعر التفعيل",
  description: "الوصف",
  tradedAmount: "الكمية المتداولة",
  averageTradePrice: "متوسط سعر التداول",
  finished: "منتهي",
  canceled: "ملغى",
  expired: "منتهي الصلاحية",
  refCode: "رمز المرجع",
  operation: "عملية",
  details: "تفاصيل"
};
