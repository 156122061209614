import $axios from "../../../utiles/axios";
import { appendToFormData, appendParam } from "../../../utiles/apiDataFormat";

export default {
  LOGIN_SERVICE: () => {
    return $axios.post("/uc/login");
  },
  CHECK_LOGIN_SERVICE: ({ commit }) => {
    return $axios.post("/uc/check/login").then((response) => {
      let userData = response.data.data;
      if (response.data.code === 0) {
        commit("global/SET_MEMBER", userData, { root: true });
        commit("global/SET_DEPOSIT_STATUS", userData.depositStatus, {
          root: true,
        });
        commit("global/SET_WITHDRAW_STATUS", userData.withdrawStatus, {
          root: true,
        });
      }
      if (response.data.code === 100) {
        commit("global/SET_TERMS_OF_USE_MODAL_STATUS", true, {
          root: true,
        });
      }
      return response;
    });
  },
  INIT_APP: ({ dispatch }) => {
    return dispatch("CHECK_LOGIN_SERVICE");
  },
  LOGOUT_SERVICE: () => {
    return $axios.post("/uc/loginout");
  },
  REGISTER_SERVICE: ({}, payload) => {
    return $axios.post("/uc/register", payload);
  },
  WALLET_SERVICE: ({}, payload) => {
    return $axios.post("/uc/asset/wallet" + appendParam(payload));
  },
  WALLET_SERVICE_V2: () => {
    return $axios.post("/uc/asset/wallet/v2");
  },
  EXCOINO_WALLETS_SERVICE: () => {
    return $axios.get("/uc/asset/top-excoino-wallets");
  },
  TOP5_WALLETS_SERVICE: () => {
    return $axios.get("/uc/asset/top5-wallet-by-usdrate");
  },
  CAPTCHA: ({}, payload) => {
    return $axios.post("/uc/start/captcha", payload);
  },
  RESET_ADDRESS_SERVICE: ({}, payload) => {
    return $axios.post(
      "/uc/asset/wallet/reset-address",
      appendToFormData(payload)
    );
  },
  GET_RECHARGE_COINS_SERVICE: () => {
    return $axios.get("/uc/coin/recharge");
  },
  GET_ALL_TRANSACTIONS_SERVICE: ({}, payload) => {
    return $axios.post(
      "/uc/asset/transaction/all/v2",
      appendToFormData(payload)
    );
  },
  GET_MEMBER_MAX_WITHDRAW_SERVICE: () => {
    return $axios.get("/uc/withdraw/find-member-max-withdraw-amount");
  },
  GET_TRANSACTION_TYPES_SERVICE: () => {
    return $axios.get("/uc/asset/transaction/types");
  },
  GET_WITHDRAW_VERIFICATION_CODE_SERVICE: () => {
    return $axios.post("/uc/mobile/withdraw/code");
  },
  APPLY_VERIFICATION_CODE_SERVICE: ({}, payload) => {
    return $axios.post("/uc/withdraw/apply/code", appendToFormData(payload));
  },
  APPLY_WITHDRAW: ({}, payload) => {
    return $axios.post("/uc/withdraw/apply/code/v3", appendToFormData(payload));
  },
  GET_WITHDRAW_ADDRESSES_LIST_SERVICE: ({}, payload) => {
    return $axios.post(
      "/uc/withdraw/support/coin/info",
      appendToFormData(payload)
    );
  },
  GET_USER_WITHDRAW_ADDRESSES_SERVICE: ({}, payload) => {
    return $axios.post("/uc/withdraw/address/page", appendToFormData(payload));
  },
  GET_WITHDRAW_COINS_LIST_SERVICE: () => {
    return $axios.post("/uc/withdraw/support/coin");
  },
  GET_WITHDRAW_RECORDS_SERVICE: ({}, payload) => {
    return $axios.post("/uc/withdraw/record", appendToFormData(payload));
  },
  GET_TFA_TYPE: ({}, payload) => {
    return $axios.get(`/uc/common/get-2fa-type?operation=${payload}`);
  },
  GET_MEMBER_DATA_SERVICE: () => {
    return $axios.post("/uc/approve/security/setting");
  },
  GET_COINS_LIST_SERVICE: ({}) => {
    return $axios.get("/uc/coin/all");
  },
  RECOVER_FUNDS_PASSWORD_SERVICE: ({}, payload) => {
    return $axios.post(
      "/uc/approve/reset/transaction/password",
      appendToFormData(payload)
    );
  },
  SET_FUNDS_PASSWORD_SERVICE: ({}, payload) => {
    return $axios.post(
      "/uc/approve/transaction/password",
      appendToFormData(payload)
    );
  },
  CHANGE_FUNDS_PASSWORD_SERVICE: ({}, payload) => {
    return $axios.post(
      "/uc/approve/update/transaction/password",
      appendToFormData(payload)
    );
  },
  GET_FUNDS_PASSWORD_VERIFICATION_CODE_SERVICE: () => {
    return $axios.post("/uc/mobile/transaction/code");
  },
  GET_ADD_ADDRESS_VERIFICATION_CODE_SERVICE: () => {
    return $axios.post("/uc/mobile/add/address/code");
  },
  DELETE_WITHDRAW_ADDRESS_SERVICE: ({}, payload) => {
    return $axios.post(
      "/uc/withdraw/address/delete",
      appendToFormData(payload)
    );
  },
  ADD_WITHDRAW_ADDRESS_SERVICE: ({}, payload) => {
    return $axios.post("/uc/withdraw/address/add", appendToFormData(payload));
  },
  CHECK_COIN_WALLET: ({}, payload) => {
    return $axios.get(`/uc/asset/check/${payload}`);
  },
  GET_COIN_NETWORK: ({}, payload) => {
    return $axios.get(`/uc/coin/support/network/${payload}`);
  },
  GET_ALL_COINS: () => {
    return $axios.get("/uc/coin/support/unit");
  },
  GET_ALL_ADDRESSES: ({}, payload) => {
    return $axios.get(
      `/uc/withdraw/address/${payload.network}/${payload.unit}`
    );
  },
};
