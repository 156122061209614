import $axios from "../../../utiles/axios";
import { appendQueryString } from "../../../utiles/apiDataFormat";

export default {
  GET_TOTAL_REWARD_FOR_MEMBER_SERVICE: () => {
    return $axios.get("/uc/reward-record/total-reward-for-member");
  },
  GET_TOTAL_REWARD_FROM_SPECIFIC_MEMBER_SERVICE: ({}, payload = {}) => {
    return $axios.get(
      "/uc/reward-record/find-total-reward-from-specific-member" +
        appendQueryString(payload)
    );
  },
  GET_TOTAL_INVITED_SERVICE: () => {
    return $axios.get("/uc/promotion/find-total-invited");
  },
  GET_ALL_INVITED_SERVICE: () => {
    return $axios.get("/uc/promotion/find-all-invited");
  },
};
