import Logo from "../IconComponents/Logo";
import Warning from "../IconComponents/Warning";
import Lock from "../IconComponents/Lock";
import BgGradient from "../IconComponents/BgGradient";
import Gift from "../IconComponents/Gift";
import Copy from "../IconComponents/Copy";
import QRCode from "../IconComponents/QRCode";
import Referral from "../IconComponents/Referral";
import ReferralBold from "../IconComponents/Referral-Bold";
import Info from "../IconComponents/Info";
import InfoSell from "../IconComponents/InfoSell";
import InfoBuy from "../IconComponents/InfoBuy";
import OcoInfoBuy from "../IconComponents/OcoInfoBuy";
import OcoInfoSell from "../IconComponents/OcoInfoSell";
import NoData from "../IconComponents/NoData";
import GrowLine from "../IconComponents/GrowLine";
import History from "../IconComponents/History";
import Wallet from "../IconComponents/Wallet";

export const icons = {
  logo: Logo,
  warning: Warning,
  lock: Lock,
  bgGradient: BgGradient,
  gift: Gift,
  copy: Copy,
  qrcode: QRCode,
  referral: Referral,
  referralBold: ReferralBold,
  Info: Info,
  InfoBuy: InfoBuy,
  InfoSell: InfoSell,
  OcoInfoBuy: OcoInfoBuy,
  OcoInfoSell: OcoInfoSell,
  NoData,
  GrowLine,
  History,
  Wallet
};
