export default {
  title: "الأسواق",
  loginToAddFavorites: "تسجيل الدخول إلى Excoino لإضافة المفضلات",
  addToFavorites: "إضافة إلى المفضلة",
  removeFavorites: "إزالة من المفضلة",
  custom: "المفضلات",
  coin: "زوج العملات",
  symbol: "زوج التداول",
  favor: "المفضلات",
  newPrice: "أحدث سعر",
  exchangeNum: "حجم التداول",
  change: "التغيير اليومي",
  priceTrend: "اتجاه السعر",
  operate: "عملية",
  trading: "تداول",
  high: "أعلى سعر",
  low: "أقل سعر",
  demoMarket: "سوق تجريبي"
};
