export default {
    dashboard: "داشبورد",
    exchange: "معامله",
    markets: "بازارها",
    assets: "دارایی‌ها",
    transactions: "تراکنش ها",
    deposit: "واریز",
    transfer: "انتقال",
    withdraw: "برداشت",
    orders: "سفارشات",
    openOrders: "سفارشات باز",
    history: "تاریخچه",
    ordersHistory: "سفارشات",
    preconditionOrdersHistory: "حد سود/ضرر",
    stopLimitOrders: "حد سود/ضرر",
    guide: "راهنما",
    excoino: "رفتن به صفحه اصلی اکسکوینو",
    excoinoPanel: "بازگشت به اکسکوینو",
    userProfile: "حساب کاربری",
    affiliateProfile: "معرفی به دوستان",
    support: "پشتیبانی",
    logout: "خروج",
    register: "ثبت نام",
    userCenter: "حساب کاربری",
    helpCenter: "پشتیبانی",
    loginRegister: "ورود/ثبت نام",
    staking: "استخر‌های مشارکت",
    activeLanguage: "زبان های فعال در اکسکوینو"
}